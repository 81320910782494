import jobService from "../services/job-services";
import { showSuccessSnackbar } from "./snackbarActions";

export const createUpdateJobProfile = (requestData, t) => (dispatch) => {
  dispatch({
    type: "SUBMIT_REQUEST",
  });
  return jobService.createUpdateJobProfile(requestData).then(
    (response) => {
      dispatch({
        type: "SUBMIT_REQUEST_SUCCESS",
      });

      let msg = response.data.message.toString();
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: msg,
        })
      );

      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "SUBMIT_REQUEST_FAILED",
      });
      return Promise.reject(error.response.data);
    }
  );
};

export const getJobProfileList = (requestData) => (dispatch) => {
  return jobService.getJobProfileList(requestData).then(
    (response) => {
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      return Promise.reject(errorResp);
    }
  );
};

export const createUpdateJobPost = (requestData, t) => (dispatch) => {
  dispatch({
    type: "SUBMIT_REQUEST",
  });
  return jobService.createUpdateJobPost(requestData).then(
    (response) => {
      dispatch({
        type: "POSTED_JOB_LIST_CLEAR",
      });
      dispatch({
        type: "SUBMIT_REQUEST_SUCCESS",
      });

      let msg = response.data.message.toString();
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: msg,
        })
      );
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "SUBMIT_REQUEST_FAILED",
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const getPostedJobList = (requestData) => (dispatch) => {
  dispatch({
    type: "POSTED_JOB_LIST_REQUEST",
  });
  return jobService.getPostedJobList(requestData).then(
    (response) => {
      dispatch({
        type: "POSTED_JOB_LIST_SUCCESS",
        payload: { postedJobList: response.data.data },
      });
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      dispatch({
        type: "POSTED_JOB_LIST_FAILED",
      });
      return Promise.reject(errorResp);
    }
  );
};

export const postedJobActions = (requestData, t) => (dispatch) => {
  return jobService.postedJobActions(requestData).then(
    (response) => {
      dispatch({
        type: "POSTED_JOB_LIST_CLEAR",
      });
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      // dispatch({
      //   type: VADHUVAR_LIST_REFRESH,
      // });
      // dispatch({
      //   type: VADHUVAR_LIST_APPROVAL_REFRESH,
      // });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const getPostedJobDetailsById = (requestData, t) => (dispatch) => {
  return jobService.getPostedJobDetailsById(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getMyPostedJob = (requestData) => (dispatch) => {
  dispatch({
    type: "SUBMIT_REQUEST",
  });
  return jobService.getMyPostedJob(requestData).then(
    (response) => {
      dispatch({
        type: "SUBMIT_REQUEST_SUCCESS",
      });
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      dispatch({
        type: "SUBMIT_REQUEST_FAILED",
      });
      return Promise.reject(errorResp);
    }
  );
};

// export const getMasterCategories = (requestData) => (dispatch) => {
//   //   dispatch({
//   //     type: COMMITTEE_MEMBER_LIST_REQUEST,
//   //   });
//   return businessService.getMasterCategories(requestData).then(
//     (response) => {

//       //   dispatch({
//       //     type: COMMITTEE_MEMBER_LIST_SUCCESS,
//       //     payload: { commiteeMembers: response.data.data },
//       //   });
//       return Promise.resolve(response.data.data);
//     },
//     (error) => {
//       //   dispatch({
//       //     type: COMMITTEE_MEMBER_LIST_FAIL,
//       //   });
//       return Promise.reject(error);
//     }
//   );
// };

// export const registration = (requestData, t) => (dispatch) => {
//   //   dispatch({
//   //     type: "VADHUVAR_REGISTRATION_SUBMIT_REQUEST",
//   //   });
//   return businessService.businessRegistration(requestData).then(
//     (response) => {
//       //   dispatch({
//       //     type: "VADHUVAR_REGISTRATION_SUBMIT_SUCCESS",
//       //   });
//       let msg = response.data.message.toString();
//       dispatch(
//         showSuccessSnackbar({
//           hideDuration: 10000,
//           status: response.data.status,
//           message: msg,
//         })
//       );
//       dispatch({
//         type: "BUSINESS_LIST_CLEAR",
//       });
//       //   dispatch({
//       //     type: VADHUVAR_LIST_APPROVAL_REFRESH,
//       //   });
//       return Promise.resolve(response.data.data);
//     },
//     (error) => {
//       //   dispatch({
//       //     type: "VADHUVAR_REGISTRATION_SUBMIT_FAILED",
//       //   });
//       return Promise.reject(error.response.data);
//     }
//   );
// };

// export const getApprovedBusiness = (requestData) => (dispatch) => {
//   dispatch({
//     type: "BUSINESS_LIST_REQUEST",
//   });
//   return businessService.getBusinessList(requestData).then(
//     (response) => {
//       dispatch({
//         type: "BUSINESS_LIST_SUCCESS",
//         payload: { businessList: response.data.data },
//       });
//       return Promise.resolve(response.data.data);
//     },
//     (errorResp) => {
//       dispatch({
//         type: "BUSINESS_LIST_FAILED",
//       });
//       return Promise.reject(errorResp);
//     }
//   );
// };

// export const businessListClear = (requestData) => (dispatch) => {
//   dispatch({
//     type: "BUSINESS_LIST_CLEAR",
//   });
// };
// export const updateBannerImage = (requestData, t) => (dispatch) => {
//   return businessService.updateBannerImage(requestData).then(
//     (response) => {
//       dispatch(
//         showSuccessSnackbar({
//           status: response.data.status,
//           message: response.data.message,
//         })
//       );
//       dispatch({
//         type: "BUSINESS_LIST_CLEAR",
//       });
//       return Promise.resolve(response.data);
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );
// };

// export const getBusinessDetailsById = (requestData, t) => (dispatch) => {
//   return businessService.getBusinessDetailsById(requestData).then(
//     (response) => {
//       return Promise.resolve(response.data);
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );
// };
