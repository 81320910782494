import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  CardContent,
  Avatar,
  CardActions,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import SwipeableTextMobileStepper from "../../components/commonComponent/SwipeableTextMobileStepper";
import { SRLWrapper } from "simple-react-lightbox";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";
import Copyright from "./CopyRight";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const useStyles = makeStyles((theme) => ({
  container: {
    // paddingTop: theme.spacing(10),
  },
  paper: {
    // height: 220,
    // width: 275,
    // backgroundColor: "#ebebeb",
  },
  avatarImage: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
}));

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

// const steps = [
//   "Create complete Profile",
//   "Make payment and became member of Vadhu-Var list",
//   "Ask for a complimentary personal guidance from us",
//   "Explore right Matches",
//   "Shortlist and express interest to perfect matches",
//   "Contact on provided details",
// ];

const steps = [
  "Create Profile",
  "Make payment",
  "Guidance from us",
  // "Explore right Matches",
  // "Shortlist and express interest to perfect matches",
  // "Contact on provided details",
  "Explore and find right Matches",
  // "Find to perfect matches",
  "Contact on provided details",
];

const stepsNext = [];

const Home = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const options = {
    settings: {
      //overlayColor: "rgb(25, 136, 124)",
      //  overlayColor: "rgb(225, 225, 230)",
      overlayColor: "#fafafa",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };

  const serviceMenu = [
    {
      id: "01",
      lable: "Vadhu Var",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbtFzYOUjBdRpw77oOHTcwf3dZJ8O5H06lMw&usqp=CAU",
      navigationPath: "/matrimony",
    },
    {
      id: "02",
      lable: "Jobs",
      imagePath:
        "https://www.seekpng.com/png/detail/212-2129332_job-opening-it-support-officer-job.png",
      //"https://freepngimg.com/thumb/jobs/9-2-jobs-png-thumb.png",
      navigationPath: "/job-updates",
    },
    {
      id: "03",
      lable: "Business",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqjCpAvZ2x7FH_ilHR_nFWc3ty2ssiazsedA&usqp=CAU",
      navigationPath: "/business",
    },
  ];
  return (
    <>
      <Grid lg={12} xs={12} md={12}>
        <Typography variant="h6" style={{ color: "blue" }} align="center">
          <Button size="large" color="primary">
            {t("welcomet_to_home")}
          </Button>
        </Typography>
      </Grid>
      <Root>
        <Grid container spacing={2}>
          <Grid lg={12} xs={12} md={12}>
            <SwipeableTextMobileStepper></SwipeableTextMobileStepper>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Divider> </Divider>
          </Grid>
          {/* <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button color="primary" size="medium">
                {t("menu_top.ourServices")}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                // startIcon={<WhatsAppIcon fontSize="small" />}
                color="primary"
                size="small"
                onClick={() => {
                  return navigate("/matrimony");
                }}
              >
                {t("menu_top.vadhu_var")}
              </Button>
              <Button
                // startIcon={<WhatsAppIcon fontSize="small" />}
                color="primary"
                size="small"
                onClick={() => {
                  return navigate("/job-updates");
                }}
              >
                Jobs
              </Button>

              <Button
                // startIcon={<WhatsAppIcon fontSize="small" />}
                color="primary"
                size="small"
                onClick={() => {
                  return navigate("/business");
                }}
              >
                {t("menu_top.business")}
              </Button>

              <Button
                // startIcon={<WhatsAppIcon fontSize="small" />}
                color="primary"
                size="small"
                onClick={() => {
                  return navigate("/committee");
                }}
              >
                {t("menu_top.committee_member")}
              </Button>
            </Box>
          </Grid> */}
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button color="primary" size="medium">
                {t("menu_top.ourServices")}
              </Button>
            </Box>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" gutterBottom>
                Welcome to suryavanshi bari portal,one of the best platform for
                our community where we have provided some services
              </Typography>
            </Box>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Grid container spacing={2}>
                {serviceMenu.map((item) => (
                  <Grid key={item.id} item lg={4} xs={12} md={12}>
                    {/* <Paper className={classes.paper} elevation={2}> */}
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                    >
                      <Button
                        onClick={() => {
                          return navigate(item.navigationPath);
                        }}
                      >
                        <Avatar
                          alt="Test "
                          className={classes.avatarImage}
                          src={item.imagePath}
                        />
                      </Button>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => {
                            return navigate(item.navigationPath);
                          }}
                        >
                          {item.lable}
                        </Button>
                      </CardActions>
                    </Grid>
                    {/* </Paper> */}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button color="primary" size="medium">
                About us
              </Button>
            </Box>
          </Grid>

          <Grid lg={12} xs={12} md={12}>
            <Typography variant="body1" gutterBottom>
              Suryavanshibari.com is one of the best platform for our community
              that has helped to find their perfect life partner.
            </Typography>

            <Typography variant="body1" gutterBottom>
              You can register and search according to your specific criteria on
              gender, specific region and location, profession, much more that
              fulfills your expectations of a happy future.
            </Typography>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" gutterBottom>
                This is first year of Suryavanshibari.com you will surely find
                the partner you always dreamed of!
              </Typography>
            </Box>
          </Grid>
          {/* <Grid lg={12} xs={12} md={12}>
            <Typography variant="body1" gutterBottom>
              Marriage is a social right that every Indian believes in and it
              has without a doubt become an important part of our culture.
              Although, the concept of love marriages is catching pace in our
              country, nothing beats the success rates of arranged marriages.
              According to Hindu mythology it is believed that marriage is a
              holy institution which connects not just two souls but two
              families as well. This is where trustworthy mediators like us
              enter the picture.
            </Typography>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Typography variant="body1" gutterBottom>
              At Suryawanshibari.com we know that the bachelors and
              bachelorettes of the 21st century depend on the internet for
              everything, hence, we decided to take a leap into online
              matchmaking. We understand how important it is for young people to
              find likeminded and compatible partners within Cast.
            </Typography>
          </Grid>

          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" gutterBottom>
                This is first year of Suryavanshibari.com you will surely find
                the partner you always dreamed of!
              </Typography>
            </Box>
          </Grid>{" "}
          <Grid lg={12} xs={12} md={12}>
            <Divider> </Divider>
          </Grid> */}
          {/* <Divider> </Divider> */}
          {/* <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              

              <Button color="primary" size="medium">
                How it works
              </Button>
            </Box>
          </Grid> */}
          {/* <Grid lg={12} xs={12} md={12}>
            <Box
              sx={{
                height: 100,
                display: "block",
                // maxWidth: 400,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <Stepper activeStep={1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid> */}
          <Grid lg={12} xs={12} md={12}>
            <Divider> </Divider>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button
                startIcon={<CallIcon fontSize="small" />}
                color="primary"
                size="medium"
              >
                Contact
              </Button>
            </Box>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" gutterBottom>
                For any kind of support please call on +91 9096 6088 27
              </Typography>
            </Box>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button
                startIcon={<WhatsAppIcon fontSize="small" />}
                color="primary"
                size="small"
                onClick={() => {
                  //window.open("https://wa.me/+919096608827", "_blank");
                  window.open(
                    "https://chat.whatsapp.com/HL5Tljuw3aq3c36qTfX0DR",
                    "_blank"
                  );
                }}
              >
                Chat on whatapp
              </Button>
            </Box>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Divider> </Divider>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <Box display="flex" justifyContent="center">
              <Button color="primary" size="medium">
                Thank you for visiting us
              </Button>
            </Box>
          </Grid>
          {/* <Grid lg={12} xs={12} md={12}>
            <Copyright sx={{ mt: 10, mb: 4 }} />
          </Grid> */}
        </Grid>
      </Root>
    </>
  );
};

export default Home;
