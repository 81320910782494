import axiosInstance from "../middleware/axiosConfig";

const getMasterCategories = (requestData) => {
  return axiosInstance.post("business/get-categories", requestData);
};
const businessRegistration = (requestData) => {
  return axiosInstance.post("business/registration", requestData);
};
const getBusinessList = (requestData) => {
  return axiosInstance.post("business/get-business-list", requestData);
};
const businessAction = (requestData) => {
  return axiosInstance.post("business/actions", requestData);
};
const updateBannerImage = (requestData) => {
  return axiosInstance.post("business/update-image", requestData);
};
const getBusinessDetailsById = (requestData) => {
  return axiosInstance.post("business/get-business-details-by-id", requestData);
};

export default {
  getMasterCategories,
  businessRegistration,
  getBusinessList,
  businessAction,
  updateBannerImage,
  getBusinessDetailsById,
};
