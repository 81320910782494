import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Grid, Avatar, Paper, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getVadhuVarApprovedList,
  wishListUpdate,
  wishListAddRemove,
  updateFilterDataPara,
} from "../../actions/vadhuVar-action";
import { getMasterData } from "../../actions/master-data-action";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import configData from "../../config/configData.json";
import AlertTitle from "@mui/material/AlertTitle";
import MuiAlert from "@material-ui/lab/Alert";

import Spinner from "react-spinner-material";
import Backdrop from "@mui/material/Backdrop";
import { BrowserView, MobileView } from "react-device-detect";

const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
  },
}));

const VadhuVar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { vadhuVar, loading, loaded } = useSelector(
    (state) => state.vadhuVarReducer
  );
  const { masterData, masterLoaded } = useSelector(
    (state) => state.masterDataReducer
  );
  const { gender, region, education, wishList } = useSelector(
    (state) => state.vadhuVarFilterDataReducers
  );
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const [regionList, setRegionList] = useState(1);
  const [educationList, setEducatioList] = useState(1);
  const [selectedCardDetails, setSelectedCardDetails] = React.useState(false);
  const [getMasters, setMasters] = useState();
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const [userAccessStatus, setUserAccessStatus] = React.useState();

  const [filterOptions, setFilterOptions] = React.useState({
    gender: gender,
    region: region,
    education: education,
  });
  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }
    if (!masterLoaded) {
      let requestData = {
        id: "1",
      };
      dispatch(getMasterData(requestData)).then((res) => {
        setTimeout(function() {
          bindDDLList(res);
          setMasters(res);
        }, 1000);
      });
    } else {
      bindDDLList(masterData);
    }
    if (loaded == false) {
      let requestData = {
        user_id: 1,
      };
      dispatch(getVadhuVarApprovedList(requestData)).then((res) => {
        setTimeout(function() {
          setUserAccessStatus(res["userAccessStatus"]);
          if (res["userAccessStatus"]["is_access"] == 0) {
            return navigate(`/registration`, {
              state: {
                fromPage: "myRegistration",
                data: "",
                parentRoute: window.location.pathname,
              },
            });
          } else {
            setItems(res["vadhuVarList"]);
          }
        }, 1000);
      });
    } else {
      if (vadhuVar.length == 0) {
        return navigate(`/registration`, {
          state: {
            fromPage: "myRegistration",
            data: "",
            parentRoute: window.location.pathname,
          },
        });
      }
      setItems(vadhuVar["vadhuVarList"]);
      applyfilter({
        gender: gender,
        region: region,
        education: education,
      });
    }
  }, [refreshKey]);

  const bindDDLList = (inputMasterData) => {
    const region_list = inputMasterData.filter((e) => e.region)[0]["region"];
    const education_list = inputMasterData.filter((e) => e.education)[0][
      "education"
    ];
    setRegionList(region_list);
    setEducatioList(education_list);
  };
  const handleReset = () => {
    let updatedItem = vadhuVar.filter((element) => {
      return element;
    });
    setItems(updatedItem);

    setFilterOptions({
      ...filterOptions,
      gender: "0",
      region: "0",
      education: "0",
    });
    dispatch(updateFilterDataPara({ gender: 0, region: 0, education: 0 }));
  };

  const handleRefresh = () => {
    let updatedItem = vadhuVar.filter((element) => {
      return element;
    });
    setItems(updatedItem);

    setFilterOptions({
      ...filterOptions,
      gender: "0",
      region: "0",
      education: "0",
    });
    dispatch(updateFilterDataPara({ gender: 0, region: 0, education: 0 }));
    let requestData = {
      user_id: 1,
    };
    dispatch(getVadhuVarApprovedList(requestData));
  };

  const handleOnChangeSerachId = (reg_id) => {
    let updatedItem = vadhuVar.filter((element) => {
      return parseInt(element.registration_number) === parseInt(reg_id);
    });
    if (reg_id == "") {
      updatedItem = vadhuVar.filter((element) => {
        return element;
      });
    }
    setItems(updatedItem);
  };
  const handleOnChangeSearchName = (name) => {
    let updatedItem = vadhuVar.filter((element) => {
      return (
        element.first_name.toLowerCase().indexOf(name) > -1 ||
        element.middle_name.toLowerCase().indexOf(name) > -1 ||
        element.last_name.toLowerCase().indexOf(name) > -1
      );
    });

    if (name == "") {
      updatedItem = vadhuVar.filter((element) => {
        return element;
      });
    }
    setItems(updatedItem);
  };

  const handleFavouriteChange = (event) => {
    setChecked(event.target.checked);
    let findWishList = event.target.checked == true ? 1 : 0;
    let updatedItem = vadhuVar.filter((element) => {
      return findWishList == 1
        ? parseInt(element.is_wish_list) === parseInt(findWishList)
        : element;
    });
    setItems(updatedItem);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleWishList = async (e) => {
    const selectedWishList = e.currentTarget.value;
    let finalValue = "";
    let d =
      selectedWishList.includes("true") || selectedWishList.includes("false");
    if (d) {
      finalValue = selectedWishList.includes("true") ? "0" : "1";
    } else {
      finalValue = parseInt(selectedWishList) == parseInt(1) ? "0" : "1";
    }

    new Promise((resolve, reject) => {
      let matchElement = {};
      const newData = items.map((itm) => {
        if (parseInt(itm.id) === parseInt(e.currentTarget.id)) {
          let matchItem = {
            ...itm,
            is_wish_list: !itm.is_wish_list,
          };
          setSelectedCardDetails(matchItem);
          matchElement = matchItem;
          return matchItem;
        }
        return itm;
      });
      setItems(newData);
      resolve(matchElement);
    }).then((matchElement) => {
      let data = {
        id: matchElement.id,
      };
      dispatch(wishListUpdate(data));
      let whish_list_update = {
        bride_groom_id: matchElement.id,
        acton_name: finalValue,
        registration_number: matchElement.registration_number,
        action_type: "bd_wish_list",
      };
      dispatch(wishListAddRemove(whish_list_update, t)).then((res) => {});
    });
  };

  const handleChangeGender = (event) => {
    setFilterOptions({
      ...filterOptions,
      gender: event.target.value,
    });

    let updatedData = {
      gender: event.target.value,
      region: filterOptions.region,
      education: filterOptions.education,
    };
    dispatch(updateFilterDataPara(updatedData));

    applyfilter(updatedData);
  };
  const handleChangeRegion = (event) => {
    setFilterOptions({
      ...filterOptions,
      region: event.target.value,
    });
    let updatedData = {
      gender: filterOptions.gender,
      region: event.target.value,
      education: filterOptions.education,
    };
    dispatch(updateFilterDataPara(updatedData));
    applyfilter(updatedData);
  };

  const handleChangeEducation = (event) => {
    setFilterOptions({
      ...filterOptions,
      education: event.target.value,
    });

    let updatedData = {
      gender: filterOptions.gender,
      region: filterOptions.region,
      education: event.target.value,
    };
    dispatch(updateFilterDataPara(updatedData));
    applyfilter(updatedData);
  };

  const applyfilter = (values) => {
    let updatedItem = [];
    updatedItem = vadhuVar
      .filter((element) => {
        return parseInt(values.gender) == parseInt("0")
          ? element
          : parseInt(element.gender) === parseInt(values.gender);
      })
      .filter((rr) => {
        return parseInt(values.region) == parseInt("0")
          ? rr
          : parseInt(rr.region_id) === parseInt(values.region);
      })
      .filter((ee) => {
        return parseInt(values.education) == parseInt("0")
          ? ee
          : parseInt(ee.education_id) === parseInt(values.education);
      });
    setItems(updatedItem);
  };

  const handleGotoRegistration = () => {
    return navigate(`/registration`, {
      state: {
        fromPage: "myRegistration",
        data: "",
        parentRoute: window.location.pathname,
      },
    });
  };

  return (
    <>
      {loading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
            onClick={handleClose}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        <>
          {userAccessStatus && userAccessStatus.is_verified == 0 ? (
            <>
              {" "}
              <Typography
                fullWidth
                style={{ color: "red" }}
                align="center"
                variant="subtitle1"
                gutterBottom
              >
                {t("matrimony_page.lbl_verificatin_pending")}
              </Typography>
            </>
          ) : (
            <>
              {userAccessStatus && userAccessStatus.is_access == 0 ? (
                <>
                  <Typography
                    fullWidth
                    style={{ color: "red" }}
                    align="center"
                    variant="subtitle1"
                    gutterBottom
                  >
                    {t("matrimony_page.lbl_no_permission")}
                  </Typography>
                </>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="gender-select-small">
                          {t("matrimony_page.lbl_gender")}
                        </InputLabel>
                        <Select
                          labelId="gender-select-small"
                          id="gender-select-small"
                          label={t("matrimony_page.lbl_gender")}
                          variant="outlined"
                          color="primary"
                          value={filterOptions.gender}
                          onChange={handleChangeGender}
                        >
                          <MenuItem value={0}>ALL</MenuItem>
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="region-select-small">
                          {" "}
                          {t("matrimony_page.lbl_region")}
                        </InputLabel>
                        <Select
                          labelId="region-select-small"
                          id="region-select-small"
                          label={t("matrimony_page.lbl_region")}
                          value={filterOptions.region}
                          variant="outlined"
                          color="primary"
                          onChange={handleChangeRegion}
                        >
                          <MenuItem value={0}>ALL</MenuItem>
                          {Object.keys(regionList).map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item}>
                                {regionList[item]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="education-select-small">
                          {t("matrimony_page.lbl_education")}
                        </InputLabel>
                        <Select
                          labelId="education-select-small"
                          id="education-select-small"
                          label={t("matrimony_page.lbl_education")}
                          variant="outlined"
                          color="primary"
                          value={filterOptions.education}
                          onChange={handleChangeEducation}
                        >
                          <MenuItem value={0}>ALL</MenuItem>
                          {Object.keys(educationList).map((item, pos) => {
                            return (
                              <MenuItem key={pos} value={item}>
                                {educationList[item]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth size="small">
                        <TextField
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          name="height"
                          onChange={(event) => {
                            handleOnChangeSerachId(event.target.value);
                          }}
                          label={t(
                            "matrimony_page.lbl_search_by_registration_number"
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth size="small">
                        <TextField
                          size="small"
                          id="outlined-basic-2"
                          variant="outlined"
                          name="name"
                          onChange={(event) => {
                            handleOnChangeSearchName(
                              event.target.value.toLowerCase()
                            );
                          }}
                          label={t("matrimony_page.lbl_search_by_name")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={6} md={6}>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={checked}
                              onChange={handleFavouriteChange}
                              inputProps={{ "aria-label": "controlled" }}
                              name="Wishlist"
                            />
                          }
                          label={t("matrimony_page.lbl_wish_list")}
                        />
                      </FormControl>{" "}
                    </Grid>
                    <Grid item lg={2} xs={4} md={4}>
                      <FormControl fullWidth>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={handleReset}
                        >
                          {t("matrimony_page.btn_reset")}
                        </Button>
                      </FormControl>

                      {/* <Box display="flex" justifyContent="center"></Box> */}
                    </Grid>
                    <Grid item lg={2} xs={4} md={4}>
                      <FormControl fullWidth>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={handleRefresh}
                        >
                          {t("common_lbl.btn_refresh")}
                        </Button>
                      </FormControl>

                      {/* <Box display="flex" justifyContent="center"></Box> */}
                    </Grid>
                    <Grid item lg={2} xs={4} md={4}>
                      <FormControl fullWidth>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={handleGotoRegistration}
                        >
                          {t("matrimony_page.lbl_registration")}
                        </Button>
                      </FormControl>

                      {/* <Box display="flex" justifyContent="center"></Box> */}
                    </Grid>
                    <Grid item lg={8} xs={12} md={12}>
                      <Box display="flex" justifyContent="center">
                        <Typography
                          style={{ color: "green" }}
                          variant="subtitle2"
                          align="center"
                          pt={1}
                        >
                          {items.length} {t("matrimony_page.lbl_profile_found")}
                        </Typography>
                      </Box>
                    </Grid>

                    {items.length == 0 ? (
                      <>
                        <Grid item lg={12} xs={12} md={12}>
                          <Alert variant="outlined" severity="warning">
                            {t("matrimony_page.lbl_no_data_found")}
                          </Alert>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item className={classes.root} xs={12}>
                          <Grid lg={12}></Grid>
                          <Grid container spacing={2}>
                            {items &&
                              items.map((item) => (
                                <Grid key={item.id} item lg={3} xs={12} md={12}>
                                  <Paper
                                    className={classes.paper}
                                    elevation={2}
                                  >
                                    <Grid
                                      container
                                      justify="center"
                                      direction="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Button
                                        id={item.id}
                                        onClick={(event) => {
                                          let selectedData = vadhuVar.filter(
                                            (e) =>
                                              e.id == event.currentTarget.id
                                          );
                                          return navigate(`/details`, {
                                            state: {
                                              data: selectedData[0],
                                              parentRoute:
                                                window.location.pathname,
                                            },
                                          });
                                        }}
                                      >
                                        <Avatar
                                          alt={item.first_name}
                                          className={classes.avatarImage}
                                          src={
                                            default_profile_images +
                                            item.appended_image_path
                                          }
                                        />
                                      </Button>

                                      <CardContent>
                                        <Typography
                                          align="center"
                                          gutterBottom
                                          variant="headline"
                                          component="h4"
                                        >
                                          {item.first_name} {item.middle_name}{" "}
                                          {item.last_name}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          align="center"
                                          color="text.secondary"
                                        >
                                          {t(
                                            "matrimony_page.lbl_registration_number"
                                          )}{" "}
                                          : {item.registration_number} <br />
                                          {t(
                                            "matrimony_page.lbl_education"
                                          )} : {item.education} <br />
                                          {t(
                                            "matrimony_page.lbl_region"
                                          )} : {item.region}
                                        </Typography>
                                      </CardContent>
                                      <CardActions>
                                        <Button
                                          size="small"
                                          id={item.id}
                                          onClick={(event) => {
                                            let selectedData = vadhuVar.filter(
                                              (e) =>
                                                e.id == event.currentTarget.id
                                            );
                                            return navigate(`/details`, {
                                              state: {
                                                data: selectedData[0],
                                                parentRoute:
                                                  window.location.pathname,
                                              },
                                            });
                                          }}
                                        >
                                          Details
                                        </Button>
                                        <ToggleButton
                                          id={item.id}
                                          value={item.is_wish_list}
                                          size="small"
                                          color="info"
                                          selected={item.is_wish_list}
                                          onChange={(e) => {
                                            handleWishList(e);
                                          }}
                                        >
                                          <FavoriteIcon size="small" />
                                        </ToggleButton>

                                        <>
                                          {/* <a
                                    href="https://web.whatsapp.com/send?text=Please Visit https://www.suryavanshibari.com"
                                    rel="nofollow noopener"
                                    target="_blank"
                                    className="share-icon"
                                  >
                                    Share via Whatsapp
                                  </a> */}

                                          <BrowserView>
                                            <Button
                                              size="small"
                                              startIcon={
                                                <ShareIcon fontSize="small" />
                                              }
                                              onClick={() => {
                                                let url =
                                                  item.id +
                                                  "-" +
                                                  item.first_name
                                                    .toLowerCase()
                                                    .trim() +
                                                  "-" +
                                                  item.last_name
                                                    .toLowerCase()
                                                    .trim();
                                                window.open(
                                                  "https://web.whatsapp.com/send?text=Please Visit  " +
                                                    process.env
                                                      .REACT_APP_PROD_URL +
                                                    "details/" +
                                                    url
                                                );
                                              }}
                                            >
                                              Share
                                            </Button>

                                            {/* <a
                                      startIcon={<ShareIcon fontSize="small" />}
                                      href="https://web.whatsapp.com/send?text=Please Visit https://www.suryavanshibari.com"
                                      rel="nofollow noopener"
                                      target="_blank"
                                      className="share-icon"
                                    >
                                      n Share
                                    </a> */}
                                          </BrowserView>
                                          <MobileView>
                                            <Button
                                              size="small"
                                              startIcon={
                                                <ShareIcon fontSize="small" />
                                              }
                                              onClick={() => {
                                                let url =
                                                  item.id +
                                                  "-" +
                                                  item.first_name
                                                    .toLowerCase()
                                                    .trim() +
                                                  "-" +
                                                  item.last_name
                                                    .toLowerCase()
                                                    .trim();
                                                window.open(
                                                  "whatsapp://send?text=Please Visit " +
                                                    process.env
                                                      .REACT_APP_PROD_URL +
                                                    "details/" +
                                                    url
                                                );
                                              }}
                                            >
                                              Share
                                            </Button>
                                          </MobileView>
                                        </>

                                        {/* <Button
                                  size="small"
                                  startIcon={<FileDownloadIcon />}
                                ></Button> */}

                                        {/* <Button
                                size="small"
                                startIcon={<FileDownloadIcon />}
                                id={item.id}
                                onClick={(event) => {
                                  let selectedData = vadhuVar.filter(
                                    (e) => e.id == event.currentTarget.id
                                  );
                                }}
                              ></Button> */}
                                      </CardActions>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VadhuVar;
