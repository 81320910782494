import React from "react";
import { Helmet } from "react-helmet-async";
export default function Landing() {
  return (
    <div>
      <Helmet>
        <title>Suryavanshi Bari</title>
        {/* <meta name="description" content="Suryavanshi Bari Samaj" /> */}
        {/* <link
          rel="shortcut icon"
          href="https://raw.githubusercontent.com/adrianhajdin/chat_application/main/public/favicon.ico"
          type="image/x-icon"
        /> */}

        {/* <meta property="og:title" content="Suryavanshi Bari " />

        <meta property="og:url" content="https://suryavanshibari.com/home" />

        <meta property="og:description" content="Suryavanshi Bari Samaj" /> */}

        <link rel="shortcut icon" href="/logo.png" type="image/x-icon" />
      </Helmet>
    </div>
  );
}
