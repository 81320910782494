import axiosInstance from "../middleware/axiosConfig";

const registration = (requestData) => {
  return axiosInstance.post("vadhu-var-registration", requestData);
};
const getVadhuVarApprovedList = (requestData) => {
  return axiosInstance.post("get-vadhu-var-approved-list", requestData);
};
const vadhuVarAction = (requestData) => {
  return axiosInstance.post("vadhu-var-action", requestData);
};
const approveReject = (requestData) => {
  return axiosInstance.post("vadhu-var/approve-reject", requestData);
};
const enableDisble = (requestData) => {
  return axiosInstance.post("vadhu-var/enable-disable", requestData);
};
const getListForApproval = (requestData) => {
  return axiosInstance.post("get-list-for-approval", requestData);
};
const wishListAddRemove = (requestData) => {
  return axiosInstance.post("vadhu-var/wish-list", requestData);
};
const approveRejectProfileImage = (requestData) => {
  return axiosInstance.post(
    "vadhu-var/approve-reject-profile-img",
    requestData
  );
};

const getOrderIdForPayment = (requestData) => {
  return axiosInstance.post("get-orderid-for-payment", requestData);
};
const savePaymentDetails = (requestData) => {
  return axiosInstance.post("save-payment-details", requestData);
};
const getVadhuVarDetailById = (requestData) => {
  return axiosInstance.post("get-vadhu-var-details-by-id", requestData);
};

const isRegistrationDone = (requestData) => {
  return axiosInstance.post("vadhu-var/get-registration-status", requestData);
};
export default {
  registration,
  vadhuVarAction,
  getVadhuVarApprovedList,
  getListForApproval,
  approveReject,
  enableDisble,
  wishListAddRemove,
  approveRejectProfileImage,
  getOrderIdForPayment,
  savePaymentDetails,
  getVadhuVarDetailById,
  isRegistrationDone,
};
