import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import { Box } from "@mui/material";
import fileUploderServices from "../../services/file-uploder-services";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@mui/material/Link";
import {
  Button,
  CardActions,
  CardMedia,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { SRLWrapper } from "simple-react-lightbox";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Cancel } from "@mui/icons-material";
import { useEffect } from "react";
import { Backdrop } from "@mui/material";
import Spinner from "react-spinner-material";

function GenFileUpload({ childToParent }) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");

  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [returnResult, setReturnResult] = useState({});
  const [openSpinner, setOpenSpinner] = React.useState(true);

  const [addOrUpdate, setAddOrUpdate] = React.useState("add");

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#EEEEEE",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }))(LinearProgress);

  const handleChooseFileChange = (e) => {
    const file = e.target.files[0];
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: "Upload Image only",
        })
      );
      return;
    } else {
      setProgress(0);
      const getFile = e.target.files[0];
      if (getFile) {
        setCurrentFile(getFile);
        setFile(getFile);
      }
    }
  };

  const handleFileUpload = (e) => {
    setLoadingSpinner(true);
    let currentSelectedFile = file;
    setProgressFlag(true);

    let data = {
      bride_groom_id: 0,
      actionType: "add_vadhu_var",
    };
    fileUploderServices
      .businessBannerImageUpload(currentSelectedFile, data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        setLoadingSpinner(false);
        dispatch(
          showSuccessSnackbar({
            status: "success",
            message: "File upload successfully",
          })
        );
        //props.fileUploadStatus(response);
        childToParent(response.data.data[0]);
      })
      .catch((err) => {
        setLoadingSpinner(false);
        dispatch(
          showSuccessSnackbar({
            status: "success",
            message: "Error while image upload,please try again later",
          })
        );
      });
  };
  const handleFileUploadCancle = () => {
    setFile(null);
    setPhotoURL("");
  };
  return (
    <>
      {loadingSpinner ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>
        </>
      ) : (
        ""
      )}

      <div className="mg20">
        {file && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        )}
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={12} xs={12} md={12}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                onChange={handleChooseFileChange}
              />
              <Button
                startIcon={<PhotoCameraIcon />}
                className="btn-choose"
                variant="outlined"
                size="small"
                component="span"
              >
                Upload banner image
              </Button>
            </label>
          </Grid>
          <Grid item lg={12} xs={12} md={12}>
            <Button
              className="btn-upload"
              size="small"
              color="primary"
              variant="contained"
              component="span"
              disabled={!currentFile}
              onClick={handleFileUpload}
            >
              Upload
            </Button>{" "}
            <div className="file-name"></div>
          </Grid>
          <Grid item lg={12} xs={12} md={12}>
            {currentFile ? currentFile.name : null}
          </Grid>
          <Grid item lg={12} xs={12} md={12}></Grid>
        </Grid>
      </div>
    </>
  );
}

export default GenFileUpload;
