import React, { useState, useEffect } from "react";
import {
  getOrderIdForPayment,
  saveRazorPayPaymentDetails,
  updateVadhuVarData,
} from "../../actions/vadhuVar-action";
import { showSuccessSnackbar } from "../../actions/snackbarActions";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import Spinner from "react-spinner-material";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
}));

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

const RazorpayPayment = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [razorPayDetails, setRazorPayDetails] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("process");
  const objectLocation = useLocation().state;
  const [paraObject, setParaObject] = useState(objectLocation.data);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { registrationFormLoading } = useSelector(
    (state) => state.vadhuVarReducer
  );
  const [configsData, setConfigsData] = useState({});
  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    } else {
      fetch(`data/configs.json`)
        .then((res) => res.json())
        .then((data) => {
          setConfigsData({ ...data });
        })
        .catch((err) => {});
      /*
      let createOrderRequestObject = {
        is_add_or_update: "add",
        pay_type: paraObject.payment_type,
      };
      dispatch(getOrderIdForPayment(createOrderRequestObject)).then((res) => {
        setRazorPayDetails(res.data);
      });
      */
    }
  }, []);

  const displayRazorpay = async (amount) => {
    const res = await loadScript(process.env.REACT_APP_RAZORPAY_LOAD_SCRIPT);
    if (!res) {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error",
          message: t("payment_page.lbl_failed_to_load"),
        })
      );
      return;
    } else {
      if (razorPayDetails.status == "created") {
        let mobileNo = "";
        const userDetails = JSON.parse(localStorage.getItem("user"));
        const isEmail = userDetails.mobile_or_email.includes("@");
        mobileNo = isEmail ? "" : userDetails.mobile_or_email;

        let getKey =
          process.env.REACT_APP_ENV == "DEV"
            ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
            : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID;

        const options = {
          key: getKey,
          amount: razorPayDetails.amount.toString(),
          order_id: razorPayDetails.id,
          name: t("payment_page.lbl_razorPay_title"),
          //description: "Thank you for nothing. Please give us some money",
          //image: "http://localhost:1337/logo.svg",
          handler: function(response) {
            const p1 = paraObject.payment_type;
            const p2 = paraObject.bride_groom_id;
            if (response.error === undefined) {
              const saveRequestObject = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                is_add_or_update: "update",
                pay_type: p1,
                bride_groom_id: p2,
              };
              setPaymentStatus("success");
              savePaymentDatatoDb(saveRequestObject);
            } else {
              setPaymentStatus("failed");
            }
          },
          prefill: {
            name: userDetails.first_name + " " + userDetails.last_name,
            email: isEmail
              ? userDetails.mobile_or_email
              : "Vinodpbhagat@gmail.com",
            phone_number: mobileNo,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    }
  };
  const gridStyles = {
    // marginTop: 2,
  };

  const btnOkGoToMyRegistration = () => {
    return navigate(`/my-registration`);
  };

  const savePaymentDatatoDb = (inputData) => {
    dispatch(saveRazorPayPaymentDetails(inputData)).then((res) => {});
  };
  const [openSpinner, setOpenSpinner] = React.useState(true);
  return (
    <>
      {registrationFormLoading ? (
        <>
          {" "}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>{" "}
        </>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            {/* <Button>Left</Button> */}
          </Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button> {t("payment_page.lbl_page_title")}</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right">
            {/* <Button>Right</Button> */}
          </Box>
        </Grid>
      </Grid>

      <Box style={gridStyles}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} md={12}>
            {paymentStatus == "process" ? (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Box
                    display="flex"
                    sx={{
                      boxShadow: 3,
                      borderRadius: 2,
                      px: 4,
                      py: 6,
                      // marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    // justifyContent="center"
                  >
                    <Typography variant="body1" gutterBottom>
                      {t("payment_page.lbl_pay_now", {
                        mobileNo: configsData && configsData.phonePay_mobile,
                        amount: configsData && configsData.phonePay_amount,
                      })}{" "}
                      {/* {configsData && configsData.phonePay_amount} .00 {"Rs"} */}
                    </Typography>{" "}
                    {/* <Button
                      variant="outlined"
                      size="small"
                      //onClick={displayRazorpay}
                      startIcon={<CurrencyRupeeIcon />}
                    >
                      {configsData && configsData.phonePay_amount} .00 {"Rs"}
                      {t("payment_page.lbl_pay_now")}
                    </Button> */}
                    {configsData ? (
                      <>
                        {/* <Typography
                        variant="subtitle1"
                        gutterBottom
                        align="center"
                      >
                        {t("contact_page.lbl_information")}{" "}
                        {configsData.contcat}
                      </Typography> */}
                        <img
                          src={
                            default_profile_images + "?type=1&id=phonePayQR.jpg"
                          }
                          // src={configsData.phonePay_image}
                          height={350}
                          alt="QR image"
                        />
                        <Typography variant="body2" gutterBottom>
                          {t("payment_page.lbl_information")}
                        </Typography>{" "}
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={btnOkGoToMyRegistration}
                          // startIcon={<CurrencyRupeeIcon />                       }
                        >
                          Ok
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={12} xs={12} md={12}>
                  <Box display="flex" justifyContent="center">
                    {paymentStatus == "success" ? (
                      <>
                        {paraObject.sourcePage == "VADHU_VAR_REG" ? (
                          <>
                            <Typography variant="body1" gutterBottom>
                              {t(
                                "matrimony_registration_page.lbl_success_message"
                              ) + paraObject.reg_number}
                            </Typography>
                          </>
                        ) : (
                          <Typography variant="body1" gutterBottom>
                            {t(
                              "matrimony_registration_page.lbl_payment_successfully_done"
                            )}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography variant="body1" gutterBottom>
                          {t("matrimony_registration_page.lbl_payment_failed") +
                            paraObject.reg_number}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        let data = {
                          refresh_or_clear: "refresh",
                        };
                        dispatch(updateVadhuVarData(data)).then(() => {
                          return navigate(`/my-registration`);
                        });
                      }}
                    >
                      {t("payment_page.lbl_btn_ok")}
                    </Button>
                  </Box>
                </Grid>{" "}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RazorpayPayment;
