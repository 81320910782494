import {
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CATEGORY_INITIAL_STATE,
  VADHUVAR_LIST_CLEAR,
  VADHUVAR_LIST_APPROVAL_CLEAR,
  COMMITTEE_MEMBER_LIST_REFRESH,
} from "./types";

import AuthService from "../services/auth.service";
import { showSuccessSnackbar } from "./snackbarActions";
export const sendOtp = (requestData, t) => (dispatch) => {
  dispatch({
    type: "REGISTER_OTP_REQUEST",
  });
  const isEmail = requestData.mobile_no.includes("@");
  return AuthService.sendOtp(requestData).then(
    (response) => {
      dispatch({
        type: "REGISTER_OTP_SEND_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "success",
          message: isEmail
            ? t("signup_page.lbl_otp_sent_on_email")
            : t("signup_page.lbl_otp_sent_on_mobile"),
        })
      );
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "REGISTER_OTP_SEND_FAILED",
      });
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error", //response.status,
          message: t(error.response.data.message),
        })
      );

      return Promise.reject(error.response.data);
    }
  );
};
export const otpVerification = (requestData, t) => (dispatch) => {
  dispatch({
    type: "REGISTER_OTP_VERIFICATION_REQUEST",
  });
  return AuthService.otpVerification(requestData).then(
    (response) => {
      dispatch({
        type: "REGISTER_OTP_VERIFICATION_SUCCESS",
        payload: {
          user: response.data.data,
          roles: response.data.data.roles,
          region: response.data.data.region,
        },
      });

      localStorage.setItem("user", JSON.stringify(response.data.data));
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "success",
          message: t(response.data.message),
        })
      );
      // if (response.data.data.is_verified == 1) {
      //   dispatch(
      //     showSuccessSnackbar({
      //       hideDuration: 10000,
      //       status: "success",
      //       message: t(response.data.message),
      //     })
      //   );
      // } else {
      //   // localStorage.clear();
      //   // localStorage.removeItem("user");
      //   // dispatch({
      //   //   type: "REGISTER_OTP_VERIFICATION_FAILED",
      //   // });
      // }
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "REGISTER_OTP_VERIFICATION_FAILED",
      });
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error", //response.status,
          message: t(error.response.data.message),
        })
      );

      return Promise.reject(error.response.data);
    }
  );
};
export const loginOld = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (response) => {
      localStorage.setItem("user", JSON.stringify(response.data.data));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: response.data.data,
          roles: response.data.data.roles,
          region: response.data.data.region,
        },
      });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};

export const login = (requestObject, t) => (dispatch) => {
  const isEmail = requestObject.mobile_no.includes("@");

  dispatch({
    type: "LOGIN_OTP_REQUEST",
  });

  return AuthService.login(requestObject).then(
    (response) => {
      dispatch({
        type: "LOGIN_OTP_SEND_SUCCESS",
      });
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "success",
          message: isEmail
            ? t("signup_page.lbl_otp_sent_on_email")
            : t("signup_page.lbl_otp_sent_on_mobile"),
        })
      );
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "LOGIN_OTP_SEND_FAILED",
      });

      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error", //response.status,
          message: t(error.response.data.message),
        })
      );
      return Promise.reject(error.response.data);
    }
  );
};

export const otpVerificationWhileLogin = (requestData, t) => (dispatch) => {
  dispatch({
    type: "LOGIN_OTP_VERIFICATION_REQUEST",
  });

  return AuthService.otpVerificationWhileLogin(requestData).then(
    (response) => {
      dispatch({
        type: "LOGIN_OTP_VERIFICATION_SUCCESS",
        payload: {
          user: response.data.data,
          roles: response.data.data.roles,
          region: response.data.data.region,
        },
      });

      dispatch(
        showSuccessSnackbar({
          hideDuration: 2000,
          status: "success",
          message: t(response.data.message),
        })
      );
      localStorage.setItem("user", JSON.stringify(response.data.data));

      /*
      if (response.data.data.is_verified == 1) {
       

        dispatch(
          showSuccessSnackbar({
            hideDuration: 2000,
            status: "success",
            message: t(response.data.message),
          })
        );
        localStorage.setItem("user", JSON.stringify(response.data.data));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: response.data.data,
            roles: response.data.data.roles,
            region: response.data.data.region,
          },
        });
      } else {
        localStorage.clear();
        localStorage.removeItem("user");
        dispatch({
          type: "LOGIN_OTP_VERIFICATION_FAILED",
        });
      }
      */
      return Promise.resolve(response.data.data);
    },
    (error) => {
      dispatch({
        type: "LOGIN_OTP_VERIFICATION_FAILED",
      });

      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error", //response.status,
          message: t(error.response.data.message),
        })
      );

      return Promise.reject(error.response.data);
    }
  );
};

export const getMasterData = (requestData, t) => (dispatch) => {
  return AuthService.getMasterData(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error.response.data);
    }
  );
};

export const saveContactUs = (requestData, t) => (dispatch) => {
  return AuthService.saveContactUS(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "success",
          message: t("contact_page.lbl_success_msg"),
        })
      );
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: "error", //response.status,
          message: t(error.response.data.message),
        })
      );

      return Promise.reject(error.response.data);
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  localStorage.clear();
  localStorage.removeItem("user");

  dispatch({
    type: VADHUVAR_LIST_CLEAR,
  });
  dispatch({
    type: VADHUVAR_LIST_APPROVAL_CLEAR,
  });
  dispatch({
    type: COMMITTEE_MEMBER_LIST_REFRESH,
  });
  dispatch({
    type: "POSTED_JOB_LIST_CLEAR",
  });
  dispatch({
    type: LOGOUT,
  });
};
