import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  makeStyles,
  Grid,
  Snackbar,
  LinearProgress,
  Box,
  AppBar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { SRLWrapper } from "simple-react-lightbox";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import ToggleButton from "@mui/material/ToggleButton";
import { useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import * as appConstant from "../../helpers/appConstant";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Avatar,
} from "@material-ui/core";
import Rightbar from "../PublicNoAuth/Rightbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
//import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import {
  vadhuVarAction,
  wishListUpdate,
  approveReject,
  enableDisable,
  wishListAddRemove,
  approveRejectProfileImage,
  getVadhuVarDetailById,
} from "../../actions/vadhuVar-action";

import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertTitle from "@mui/material/AlertTitle";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import configData from "../../config/configData.json";
import CropEasy from "../crop/CropEasy";
import fileUploderServices from "../../services/file-uploder-services";
import { showSuccessSnackbar } from "../../actions/snackbarActions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { spacing } from "@mui/system";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { red } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import { Backdrop } from "@mui/material";
import Spinner from "react-spinner-material";
import { BrowserView, MobileView } from "react-device-detect";

const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  avatarImage: {
    width: 250,
    height: 250,
    borderRadius: 10,
  },
  custom: {
    fontWeight: "bold",
  },
  appTitleheader: {
    background: "#cfd8dc",
  },
}));

const VadhuVarDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAdd, setIsAdd] = useState(true);
  var vadhuVarObjectLocation = useLocation().state;
  const [vadhuVarObject, setVadhuVarObject] = React.useState(
    vadhuVarObjectLocation == null ? {} : vadhuVarObjectLocation.data
  );

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);
  const [actionMessage, setActionMessage] = React.useState({});
  const [response, setResponse] = useState();

  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [progressFlag, setProgressFlag] = useState(false);
  const [fileUploaded, setFileUploaded] = useState({});
  const [isChangeProfilePhoto, setIsChangeProfilePhoto] = useState(false);
  const [isPaymentEnable, setIsPaymentEnable] = useState(false);
  const [openSpinner, setOpenSpinner] = React.useState(true);
  const [isDirectUrlHit, setDirectUrlHit] = React.useState(false);
  const { registrationFormLoading } = useSelector(
    (state) => state.vadhuVarReducer
  );
  const { commonLoading } = useSelector((state) => state.commonLoadingReducer);
  const colorRed = red["500"];

  // var imgType =
  //   vadhuVarObject.registration_status == appConstant.ACTION_APPROVED ? 1 : 0;
  // if (imgType == 1) {
  //   if (vadhuVarObject.is_profile_img_approved == 0) {
  //     imgType = vadhuVarObjectLocation.parentRoute == "/matrimony" ? 1 : 0;
  //   }
  // }
  const imgPath = "get-vadhu-var";
  //?type=" + imgType + "&id=";
  const default_profile_images = IMAGE_API_URL + imgPath;
  const { id } = useParams();
  useEffect(() => {
    paymentModule();
    if (vadhuVarObjectLocation == null) {
      const userDetails = JSON.parse(localStorage.getItem("user"));
      if (userDetails != null) {
        let getUrlParameter = id.split("-");
        setDirectUrlHit(true);
        let details = {
          bride_groom_id: getUrlParameter[0],
          bride_groom_name: getUrlParameter[1] + " " + getUrlParameter[2],
          region_name: "No",
        };
        dispatch(getVadhuVarDetailById(details, t)).then(
          (res) => {
            if (Object.keys(res).length === 0) {
              return navigate("/matrimony");
            } else {
              setVadhuVarObject(res[0]);
            }
          },
          (err) => {
            return navigate("/matrimony");
          }
        );
      } else {
        return navigate(`/login`, {
          state: {
            data: "",
            parentRoute: window.location.pathname,
          },
        });
      }
    } else {
      // yuo can find all params from here
      if (vadhuVarObject !== undefined) {
        let details = {
          bride_groom_id: vadhuVarObject.id,
          bride_groom_name:
            vadhuVarObject.first_name +
            " " +
            vadhuVarObject.middle_name +
            " " +
            vadhuVarObject.last_name,
          region_name: vadhuVarObject.region,
        };

        dispatch(getVadhuVarDetailById(details, t)).then((res) => {});
      }
    }
  }, []);

  function handleClick(event) {
    event.preventDefault();
  }
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const options = {
    icons: {
      autoplayIcon: null,
      downloadIcon: false,
    },

    settings: {
      //overlayColor: "rgb(25, 136, 124)",
      //  overlayColor: "rgb(225, 225, 230)",
      overlayColor: "#fafafa",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "#1b5245",
      iconColor: "rgba(126, 172, 139, 0.8)",
      showDownloadButton: false,
      showAutoplayButton: false,
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };

  const action = () => {
    setOpen(true);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const [open, setOpen] = React.useState(false);
  const [remark, setRemark] = useState({
    value: "",
  });

  const handleChangeRemark = (e) => {
    setRemark({ value: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paymentModule = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails != null) {
      if (JSON.parse(userDetails.feature.isPaymentModuleEnable)) {
        setIsPaymentEnable(true);
      } else {
        setIsPaymentEnable(false);
      }
    }
  };

  const submitActionData = () => {
    if (actionMessage.actionType == appConstant.ACTIONT_TYPE_APPROVE_REJECT) {
      var rejected_remark = "";
      if (actionMessage.action == appConstant.ACTION_PENDING) {
      } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
        rejected_remark = vadhuVarObject.image_path;
      } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
        rejected_remark = remark.value;
      }
      let data = {
        bride_groom_id: vadhuVarObject.id,
        acton_name: actionMessage.action,
        registration_number: vadhuVarObject.registration_number,
        acton_remark: rejected_remark,
        action_type: actionMessage.actionType,
      };
      dispatch(approveReject(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    } else if (
      actionMessage.actionType == appConstant.ACTIONT_TYPE_ENABLE_DISABLE ||
      actionMessage.actionType == appConstant.ACTIONT_Is_VADHU_VAR_PROFILE_LOCK
    ) {
      let data = {
        bride_groom_id: vadhuVarObject.id,
        acton_name: actionMessage.action,
        registration_number: vadhuVarObject.registration_number,
        action_type: actionMessage.actionType,
      };
      dispatch(enableDisable(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    } else if (
      actionMessage.actionType == appConstant.ACTIONT_TYPE_PROFILE_PHOTO
    ) {
      var rejected_remark = "";
      if (actionMessage.action == appConstant.ACTION_PENDING) {
      } else if (actionMessage.action == appConstant.ACTION_APPROVED) {
        rejected_remark = vadhuVarObject.image_path;
      } else if (actionMessage.action == appConstant.ACTION_REJECTED) {
        rejected_remark = remark.value;
      }
      let data = {
        bride_groom_id: vadhuVarObject.id,
        acton_name: actionMessage.action,
        registration_number: vadhuVarObject.registration_number,
        acton_remark: rejected_remark,
        action_type: actionMessage.actionType,
      };
      dispatch(approveRejectProfileImage(data))
        .then((res) => {
          return navigate(vadhuVarObjectLocation.parentRoute);
        })
        .catch(() => {
          setOpen(false);
        });
    }
  };

  const handleChooseFileChange = (e) => {
    const file = e.target.files[0];
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      dispatch(
        showSuccessSnackbar({
          status: "error",
          message: t("matrimony_registration_page.lbl_valid_photo_error"),
        })
      );
      return;
    } else {
      setIsChangeProfilePhoto(true);
      setProgress(0);
      if (file) {
        setFile(file);
        setPhotoURL(URL.createObjectURL(file));
        setOpenCrop(true);
      }
    }
  };

  const handleFileUpload = (e) => {
    dispatch({
      type: "VADHUVAR_APPROVAL_REQUEST",
    });

    let currentSelectedFile = file;
    setProgressFlag(true);
    setCurrentFile(currentSelectedFile);
    let data = {
      bride_groom_id: vadhuVarObject.id,
      registration_status: vadhuVarObject.registration_status,
      actionType: "update_vadhu_var",
      image_path: vadhuVarObject.image_path,
    };
    fileUploderServices
      .upload(currentSelectedFile, data, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        dispatch({
          type: "VADHUVAR_APPROVAL_REQUEST_SUCCESS",
        });

        dispatch({
          type: "VADHUVAR_LIST_REFRESH",
        });
        dispatch({
          type: "VADHUVAR_LIST_APPROVAL_REFRESH",
        });
        setProgressFlag(false);
        dispatch(
          showSuccessSnackbar({
            status: response.data.status,
            message: response.data.message,
          })
        );
        handleFileUploadCancle();
        return navigate(vadhuVarObjectLocation.parentRoute);
      })
      .catch((err) => {
        dispatch({
          type: "VADHUVAR_APPROVAL_REQUEST_FAILED",
        });
        dispatch(
          showSuccessSnackbar({
            status: "error",
            message: "Error occured while crop, please try again later",
          })
        );
      });
  };

  const handleFileUploadCancle = () => {
    setFile(null);
    setPhotoURL("");
    setIsChangeProfilePhoto(false);
  };

  const handleOnFileChanges = (data) => {
    setIsChangeProfilePhoto(true);
  };

  const handleOnFileUpload = (data) => {};
  const handleOnFileUploadCancle = (data) => {
    setIsChangeProfilePhoto(false);
  };

  return (
    <>
      {registrationFormLoading || commonLoading ? (
        <>
          {" "}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={openSpinner}
          >
            <Spinner radius={50} color={"#e60e96"} stroke={2} visible={true} />
          </Backdrop>{" "}
        </>
      ) : (
        ""
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid></Grid>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} md={12}>
                {actionMessage.confirmation}
              </Grid>
              {actionMessage &&
              actionMessage.action == appConstant.ACTION_REJECTED ? (
                <Grid item lg={12} xs={12} md={12}>
                  <TextField
                    id="txtRemark"
                    onChange={handleChangeRemark}
                    // id="outlined-basic"
                    label="Enter remark"
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    rows={2}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<HighlightOffIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={submitActionData}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Root>
        {/* <Box sx={{ flexGrow: 1 }}>
          <Grid
            alignContent="center"
            lg={12}
            style={{
              background: "#cfd8dc",
            }}
          >
            <Toolbar>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  return navigate(vadhuVarObjectLocation.parentRoute);
                }}
              >
                Back
              </Button>
              <Typography
                align="center"
                component="div"
                color="primary"
                sx={{ flexGrow: 1 }}
              >
                {t("matrimony_details_page.title")}
              </Typography>
            </Toolbar>
          </Grid>
        </Box> */}

        <Grid
          container
          spacing={2}
          style={{
            background: "#cfd8dc",
          }}
        >
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="left">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  if (isDirectUrlHit == true) {
                    return navigate("/matrimony");
                  } else {
                    return navigate(vadhuVarObjectLocation.parentRoute);
                  }
                }}
              >
                Back
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8} xs={8} md={8}>
            <Box display="flex" justifyContent="center">
              <Button> {t("matrimony_details_page.title")}</Button>
            </Box>
          </Grid>
          <Grid item lg={2} xs={2} md={2}>
            <Box display="flex" justifyContent="right">
              {vadhuVarObject &&
              vadhuVarObject.is_edit &&
              vadhuVarObject.is_lock == 0 &&
              isDirectUrlHit == false ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    return navigate(`/registration`, {
                      state: {
                        fromPage: "edit",
                        data: vadhuVarObjectLocation,
                        childRoute: window.location.pathname,
                      },
                    });
                  }}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {vadhuVarObject ? (
            <>
              {" "}
              <Grid item lg={9} xs={12} md={12}>
                <SRLWrapper options={options}>
                  <Card className={classes.card}>
                    <Grid container spacing={1}>
                      <Grid item lg={6} xs={12} md={12}>
                        {isChangeProfilePhoto == false ? (
                          <>
                            {" "}
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Avatar
                                alt={vadhuVarObject.first_name}
                                className={classes.avatarImage}
                                src={
                                  default_profile_images +
                                  vadhuVarObject.appended_image_path
                                }
                              />
                            </Grid>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {vadhuVarObject.is_edit &&
                        vadhuVarObject.is_lock == 0 ? (
                          <>
                            {" "}
                            {openCrop ? (
                              <CropEasy
                                {...{
                                  photoURL,
                                  setOpenCrop,
                                  setPhotoURL,
                                  setFile,
                                }}
                              />
                            ) : (
                              <>
                                <Grid item lg={12} xs={12} md={12}>
                                  {progressFlag ? (
                                    <>
                                      {" "}
                                      <Grid item lg={12}>
                                        {currentFile && (
                                          <>
                                            <Box sx={{ width: "100%" }}>
                                              <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                              />
                                            </Box>
                                          </>
                                        )}
                                      </Grid>{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Grid item lg={12}>
                                    <label htmlFor="btn-upload">
                                      <input
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={handleChooseFileChange}
                                      />
                                      <Button
                                        color="primary"
                                        startIcon={<PhotoCameraIcon />}
                                        className="btn-choose"
                                        variant="outlined"
                                        component="span"
                                        size="small"
                                        style={{ marginTop: 5 }}
                                      >
                                        Change photo
                                      </Button>
                                    </label>
                                  </Grid>

                                  {photoURL ? (
                                    <>
                                      <Grid item lg={12}>
                                        <SRLWrapper>
                                          <CardMedia
                                            component="img"
                                            sx={{ borderRadius: "10%" }}
                                            image={photoURL}
                                            height="225"
                                          />
                                        </SRLWrapper>
                                      </Grid>

                                      <Grid item lg={12}>
                                        <CardActions>
                                          <Button
                                            startIcon={<CloudUploadIcon />}
                                            className="btn-choose"
                                            variant="outlined"
                                            component="span"
                                            size="small"
                                            color="primary"
                                            onClick={handleFileUpload}
                                          >
                                            Upload
                                          </Button>

                                          <Button
                                            startIcon={<Cancel />}
                                            className="btn-choose"
                                            variant="outlined"
                                            component="span"
                                            color="secondary"
                                            size="small"
                                            onClick={handleFileUploadCancle}
                                          >
                                            cancel
                                          </Button>
                                        </CardActions>
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Grid item lg={12}></Grid>
                                </Grid>
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item lg={6} xs={12} md={12}>
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                              >
                                {vadhuVarObject.first_name}{" "}
                                {vadhuVarObject.middle_name}{" "}
                                {vadhuVarObject.last_name}
                              </Typography>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t(
                                  "matrimony_details_page.lbl_registration_number"
                                )}{" "}
                                :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.registration_number}
                              </Typography>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_details_page.lbl_profile_status")}{" "}
                                :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.registration_status}
                              </Typography>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_details_page.lbl_education")} :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.education}
                                {vadhuVarObject.specialization != ""
                                  ? " (" + vadhuVarObject.specialization + ")"
                                  : ""}
                              </Typography>
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                              {" "}
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_details_page.lbl_date_of_birth")}{" "}
                                :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.date_of_birth}
                              </Typography>{" "}
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_details_page.lbl_height")} :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.height}
                              </Typography>
                            </Grid>

                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_details_page.lbl_region")} :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.region}
                                {vadhuVarObject.city_village != ""
                                  ? "(" + vadhuVarObject.city_village + ")"
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{ display: "inline-block" }}
                              >
                                {t("matrimony_registration_page.lbl_cast")} :
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                }}
                              >
                                {vadhuVarObject.cast_name}{" "}
                                {vadhuVarObject.sub_cast_name != ""
                                  ? "(" + vadhuVarObject.sub_cast_name + ")"
                                  : ""}
                              </Typography>
                            </Grid>

                            {vadhuVarObject.parentPage == "approval_page" &&
                            vadhuVarObject.is_profile_img_approval == 1 &&
                            vadhuVarObject.is_approval ? (
                              <>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  component="div"
                                >
                                  Need to verify and approve the profile Photo.
                                </Typography>
                                <CardActions>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    startIcon={<CheckCircleOutlineIcon />}
                                    onClick={() => {
                                      let data = {
                                        action: appConstant.ACTION_APPROVED,
                                        confirmation:
                                          "Are you sure you want to approve profile photo ?",
                                        actionType:
                                          appConstant.ACTIONT_TYPE_PROFILE_PHOTO,
                                      };
                                      setActionMessage(data);
                                      action();
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    startIcon={<HighlightOffIcon />}
                                    onClick={() => {
                                      let data = {
                                        action: appConstant.ACTION_REJECTED,
                                        confirmation:
                                          "Are you sure you want to reject profile photo ?",
                                        actionType:
                                          appConstant.ACTIONT_TYPE_PROFILE_PHOTO,
                                      };
                                      setActionMessage(data);
                                      action();
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </CardActions>
                              </>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </CardContent>
                        <CardActions>
                          {vadhuVarObject &&
                          vadhuVarObject.registration_status == "Approved" ? (
                            <>
                              <ToggleButton
                                id={vadhuVarObject.id}
                                value={
                                  vadhuVarObject.is_wish_list == 1
                                    ? true
                                    : false
                                }
                                color="info"
                                fontSize="small"
                                selected={
                                  vadhuVarObject.is_wish_list == 1
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  const selectedWishList =
                                    e.currentTarget.value;
                                  let finalValue = "";
                                  let d =
                                    selectedWishList.includes("true") ||
                                    selectedWishList.includes("false");

                                  if (d) {
                                    finalValue = selectedWishList.includes(
                                      "true"
                                    )
                                      ? "0"
                                      : "1";
                                  } else {
                                    finalValue =
                                      parseInt(selectedWishList) == parseInt(1)
                                        ? "0"
                                        : "1";
                                  }
                                  setVadhuVarObject({
                                    ...vadhuVarObject,
                                    is_wish_list: !vadhuVarObject.is_wish_list,
                                  });
                                  vadhuVarObject.is_wish_list =
                                    finalValue == 1 ? true : false;

                                  let data = {
                                    id: vadhuVarObject.id,
                                  };
                                  dispatch(wishListUpdate(data));
                                  let whish_list_update = {
                                    bride_groom_id: vadhuVarObject.id,
                                    acton_name: finalValue,

                                    registration_number:
                                      vadhuVarObject.registration_number,
                                    action_type: "bd_wish_list",
                                  };
                                  dispatch(
                                    wishListAddRemove(whish_list_update, t)
                                  ).then((res) => {});
                                }}
                              >
                                <FavoriteIcon />
                              </ToggleButton>
                            </>
                          ) : (
                            ""
                          )}

                          {vadhuVarObject &&
                          vadhuVarObject.registration_status == "Approved" ? (
                            <>
                              {" "}
                              <Button
                                size="large"
                                color="primary"
                                startIcon={
                                  <PictureAsPdfIcon fontSize="small" />
                                }
                                id={vadhuVarObject.id}
                                onClick={(event) => {
                                  let imageName = vadhuVarObject.image_path.split(
                                    "."
                                  );
                                  let pdfURl =
                                    IMAGE_API_URL +
                                    "download-pfd?id=" +
                                    imageName[0];
                                  saveAs(
                                    pdfURl,
                                    vadhuVarObject.first_name +
                                      "_" +
                                      vadhuVarObject.last_name +
                                      "_" +
                                      vadhuVarObject.registration_number +
                                      ".pdf"
                                  );
                                }}
                              ></Button>
                              <>
                                <BrowserView>
                                  <Button
                                    size="small"
                                    startIcon={<ShareIcon fontSize="small" />}
                                    onClick={() => {
                                      let url =
                                        vadhuVarObject.id +
                                        "-" +
                                        vadhuVarObject.first_name
                                          .toLowerCase()
                                          .trim() +
                                        "-" +
                                        vadhuVarObject.last_name
                                          .toLowerCase()
                                          .trim();
                                      window.open(
                                        "https://web.whatsapp.com/send?text=Please Visit " +
                                          process.env.REACT_APP_PROD_URL +
                                          "details/" +
                                          url
                                      );
                                    }}
                                  >
                                    Share
                                  </Button>
                                </BrowserView>
                                <MobileView>
                                  <Button
                                    size="small"
                                    startIcon={<ShareIcon fontSize="small" />}
                                    onClick={() => {
                                      let url =
                                        vadhuVarObject.id +
                                        "-" +
                                        vadhuVarObject.first_name
                                          .toLowerCase()
                                          .trim() +
                                        "-" +
                                        vadhuVarObject.last_name
                                          .toLowerCase()
                                          .trim();
                                      window.open(
                                        "whatsapp://send?text=Please Visit " +
                                          process.env.REACT_APP_PROD_URL +
                                          "details/" +
                                          url
                                      );
                                    }}
                                  >
                                    Share
                                  </Button>
                                </MobileView>
                              </>
                            </>
                          ) : (
                            ""
                          )}

                          {vadhuVarObject &&
                          vadhuVarObject.is_edit &&
                          vadhuVarObject.payment_status != "Done" ? (
                            <>
                              {vadhuVarObject.isPaymentEnable ? (
                                <>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    startIcon={<CurrencyRupeeRoundedIcon />}
                                    id={vadhuVarObject.id}
                                    onClick={(event) => {
                                      return navigate(`/payment`, {
                                        state: {
                                          data: {
                                            bride_groom_id: vadhuVarObject.id,
                                            reg_number:
                                              vadhuVarObject.registration_number,
                                            payment_type: "VADHU_VAR_REG",
                                            sourcePage: "VADHU_VAR_DETAILS",
                                          },
                                          parentRoute: window.location.pathname,
                                        },
                                      });
                                    }}
                                  >
                                    {t("payment_page.lbl_make_a_payment")}{" "}
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </CardActions>
                      </Grid>
                    </Grid>
                    <CardContent>
                      <Grid container spacing={1}>
                        {appConstant.ACTION_REJECTED ===
                          vadhuVarObject.registration_status &&
                        vadhuVarObject.is_edit ? (
                          <Grid item lg={12} xs={12} md={12}>
                            <div style={{ display: "flex" }}>
                              <Typography
                                variant="subtitle2"
                                className={classes.custom}
                                style={{
                                  flexDirection: "column",
                                  color: "red",
                                }}
                              >
                                {t(
                                  "matrimony_details_page.lbl_rejected_reasons"
                                )}{" "}
                                :
                              </Typography>
                              <Typography
                                variant="body2"
                                noWrap
                                style={{
                                  display: "inline-block",
                                  marginLeft: 6,
                                  color: "red",
                                }}
                              >
                                {vadhuVarObject.rejected_remark}
                              </Typography>
                            </div>
                          </Grid>
                        ) : (
                          <>
                            {vadhuVarObject.is_profile_img_approved === 0 &&
                            vadhuVarObject.is_profile_img_newly_uploded == 0 &&
                            vadhuVarObject.is_edit ? (
                              <>
                                <Grid item lg={12} xs={12} md={12}>
                                  <div style={{ display: "flex" }}>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.custom}
                                      style={{
                                        flexDirection: "column",
                                        color: "red",
                                      }}
                                    >
                                      {t(
                                        "matrimony_details_page.lbl_rejected_reasons"
                                      )}{" "}
                                      :
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      noWrap
                                      style={{
                                        display: "inline-block",
                                        marginLeft: 6,
                                        color: "red",
                                      }}
                                    >
                                      {vadhuVarObject.rejected_remark}
                                    </Typography>
                                  </div>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}

                        {/* <Grid item lg={12} xs={12} md={12}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="subtitle2"
                          className={classes.custom}
                          style={{ flexDirection: "column", color: "red" }}
                        >
                          Rejected reseaon :
                        </Typography>
                        <Typography
                          variant="body2"
                          noWrap
                          style={{
                            display: "inline-block",
                            marginLeft: 6,
                            color: "red",
                          }}
                        >
                          {vadhuVarObject.rejected_remark}
                        </Typography>
                      </div>
                    </Grid> */}

                        <Grid item lg={12} xs={12} md={12}>
                          <Divider>
                            <Chip label="OTHER DETAILS" />
                          </Divider>
                        </Grid>

                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_details_page.lbl_gender")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            align="center"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.gender == 1 ? "Male" : "Female"}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_details_page.lbl_married_status")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.married_status_id == 1
                              ? "Married(पुनर्विवाह)"
                              : "Unmarried"}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_details_page.lbl_uncle_name")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.uncle_surname}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_details_page.lbl_birth_place_time")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.birth_place_time}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_registration_page.lbl_zodiac")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.zodiac_name}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            style={{ display: "inline-block" }}
                          >
                            {t("matrimony_details_page.lbl_requriement")} :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.requirement}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} md={12}>
                          <Divider>
                            <Chip label="OCCUPATION DETAILS" />
                          </Divider>
                        </Grid>

                        <Grid item lg={6} xs={12} md={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.custom}
                              color="inherit"
                              noWrap
                              style={{ flexDirection: "column" }}
                            >
                              {t("matrimony_details_page.lbl_occupation")} :
                            </Typography>
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ marginLeft: 6 }}
                            >
                              {vadhuVarObject.occupation}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.custom}
                              style={{ flexDirection: "column" }}
                            >
                              {t("matrimony_details_page.lbl_annual_income")} :
                            </Typography>
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ display: "inline-block", marginLeft: 6 }}
                            >
                              {vadhuVarObject.annual_income}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={12} xs={12} md={12}>
                          <Divider>
                            <Chip label="FAMILY DETAILS" />
                          </Divider>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.custom}
                              style={{ flexDirection: "column" }}
                            >
                              {t("matrimony_details_page.lbl_father_name")} :
                            </Typography>
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ display: "inline-block", marginLeft: 6 }}
                            >
                              {vadhuVarObject.father_name}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={4} xs={12} md={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.custom}
                              style={{ flexDirection: "column" }}
                            >
                              {t("matrimony_details_page.lbl_father_mobile")} :
                            </Typography>
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ display: "inline-block", marginLeft: 6 }}
                            >
                              {vadhuVarObject.father_mobile_number}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item lg={4} xs={12} md={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              className={classes.custom}
                              style={{ flexDirection: "column" }}
                            >
                              {t(
                                "matrimony_details_page.lbl_father_occupation"
                              )}{" "}
                              :
                            </Typography>
                            <Typography
                              variant="body2"
                              noWrap
                              style={{ display: "inline-block", marginLeft: 6 }}
                            >
                              {vadhuVarObject.father_occupation}
                            </Typography>
                          </div>
                        </Grid>

                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t(
                              "matrimony_details_page.lbl_father_current_address"
                            )}{" "}
                            :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block" }}
                          >
                            {vadhuVarObject.father_current_address}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                          >
                            {t(
                              "matrimony_details_page.lbl_father_permanent_address"
                            )}{" "}
                            :
                          </Typography>
                          <Typography variant="body2">
                            {vadhuVarObject.father_permanent_address}
                          </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12} md={12}>
                          <Divider>
                            <Chip label="CONTACT PERSON" />
                          </Divider>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t(
                              "matrimony_details_page.lbl_contat_person_name_1"
                            )}{" "}
                            :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.contact_person_name_first}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t(
                              "matrimony_details_page.lbl_contat_person_mobile_1"
                            )}{" "}
                            :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.contact_person_mobile_number_first}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t(
                              "matrimony_details_page.lbl_contat_person_name_2"
                            )}{" "}
                            :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.contact_person_name_second}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} md={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.custom}
                            style={{ display: "inline-block" }}
                          >
                            {t(
                              "matrimony_details_page.lbl_contat_person_mobile_2"
                            )}{" "}
                            :
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ display: "inline-block", marginLeft: 6 }}
                          >
                            {vadhuVarObject.contact_person_mobile_number_second}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>

                    {vadhuVarObject.is_approval ? (
                      <>
                        <Grid spacing={4}>
                          <Grid item lg={12} xs={6} md={6}>
                            <Divider variant="middle" />
                            <Stack
                              justifyContent="center"
                              alignItems="flex-start"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              direction={{ xs: "column", sm: "row" }}
                              spacing={{ xs: 1, sm: 1, md: 1 }}
                              sx={{ m: 2 }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<CheckCircleOutlineIcon />}
                                onClick={() => {
                                  let data = {
                                    action: appConstant.ACTION_APPROVED,
                                    confirmation:
                                      "Are you sure you want to approve ?",
                                    actionType:
                                      appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                                  };
                                  setActionMessage(data);
                                  action();
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                startIcon={<HighlightOffIcon />}
                                onClick={() => {
                                  let data = {
                                    action: appConstant.ACTION_REJECTED,
                                    confirmation:
                                      "Are you sure you want to reject ?",
                                    actionType:
                                      appConstant.ACTIONT_TYPE_APPROVE_REJECT,
                                  };
                                  setActionMessage(data);
                                  action();
                                }}
                              >
                                Reject
                              </Button>
                              <Button
                                variant="outlined"
                                color="#2196f3"
                                size="small"
                                startIcon={<AddTaskIcon />}
                                onClick={() => {
                                  let statuname =
                                    vadhuVarObject.row_status == 1
                                      ? "deactive"
                                      : "active";

                                  let message =
                                    "Are you sure you want to " +
                                    statuname +
                                    " ?";
                                  let data = {
                                    action:
                                      vadhuVarObject.row_status == 1
                                        ? appConstant.ACTION_DISABLED
                                        : appConstant.ACTION_ENABLED,
                                    confirmation: message,
                                    actionType:
                                      appConstant.ACTIONT_TYPE_ENABLE_DISABLE,
                                  };
                                  setActionMessage(data);
                                  action();
                                }}
                              >
                                {vadhuVarObject.row_status == 1
                                  ? "Active"
                                  : "Deactive"}
                              </Button>
                              <Button
                                variant="outlined"
                                color={colorRed}
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  let data = {
                                    action: appConstant.ACTION_DELETED,
                                    confirmation:
                                      "Are you sure you want to delete ?",
                                    actionType:
                                      appConstant.ACTIONT_TYPE_ENABLE_DISABLE,
                                  };
                                  setActionMessage(data);
                                  action(appConstant.ACTION_DELETED);
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                variant="outlined"
                                color={colorRed}
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  let statuname =
                                    vadhuVarObject.is_lock == 0
                                      ? "lock"
                                      : "unlock";

                                  let data = {
                                    action:
                                      vadhuVarObject.is_lock == 0 ? "1" : "0",
                                    // vadhuVarObject.is_lock == 0
                                    //   ? "locked"
                                    //   : "unlocked",
                                    confirmation:
                                      "Are you sure you want to " +
                                      statuname +
                                      " profile?",
                                    actionType:
                                      appConstant.ACTIONT_Is_VADHU_VAR_PROFILE_LOCK,
                                  };
                                  setActionMessage(data);
                                  action(vadhuVarObject.is_lock == 0 ? 1 : 0);
                                }}
                              >
                                {vadhuVarObject.is_lock == 0
                                  ? "Locked"
                                  : "Unlocked"}
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Card>
                </SRLWrapper>
              </Grid>
            </>
          ) : (
            ""
          )}

          <Grid item lg={3} xs={12} md={12}></Grid>
        </Grid>
      </Root>
    </>
  );
};

export default VadhuVarDetails;
