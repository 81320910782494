import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import tEn from "../../locales/en/transaltion.json";
import tHi from "../../locales/hi/transaltion.json";
import tmr_IN from "../../locales/mr_IN/transaltion.json";

import { Grid, makeStyles, Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import Feed from "../PublicNoAuth/Feed";
import Leftbar from "../menu/Leftbar";
//import Navbar from "./components/Navbar";
//import Rightbar from "../";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import InfoIcon from "@mui/icons-material/Info";
import ContactsIcon from "@mui/icons-material/Contacts";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ViewStreamOutlinedIcon from "@mui/icons-material/ViewStreamOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LanguageIcon from "@mui/icons-material/Language";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";

import { deepPurple } from "@mui/material/colors";
import {
  Bookmark,
  ExitToApp,
  Home,
  Person,
  PhotoCamera,
  PlayCircleOutline,
  Settings,
  Storefront,
  TabletMac,
} from "@material-ui/icons";
import { logout } from "../../actions/auth";
const drawerWidth = 240;
//const navItems = ["Home", "About", "Gallary", "Members", "Contact", "sign in"];

const navItemsObject = [
  { path: "/", name: "Home", menuflag: true },
  { path: "/about", name: "About", menuflag: true },
  { path: "/gallary", name: "Gallary", menuflag: true },
  { path: "/committee", name: "Members", menuflag: true },
  // { path: "/matrimony", name: "Matrimony", menuflag: true },
  { path: "/contact", name: "Contact", menuflag: true },
  {
    path: "/login",
    name: "Login",
  },
  // { path: "/registration", name: "Registration", menuflag: true },
];

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: tEn,
      },
      // de: {
      //   translation: tHi,
      // },
      hi: {
        translation: tmr_IN,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function DrawerAppBar(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({ open });
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loginUser, setLoginUser] = useState({});

  useEffect(() => {
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setTimeout(function() {
      getLoginUserInfo();
    }, 1000);
  }, []);

  const getLoginUserInfo = () => {
    const promiseResult = new Promise((resolve, reject) => {
      const getLoginUserDetails = localStorage.getItem("user");
      if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
        let userObject = {};
        userObject = JSON.parse(getLoginUserDetails);
        resolve(userObject);
      }
    });
    promiseResult.then((result) => {
      setLoginUser(result);
    });
  };

  const handleDrawerToggle = () => {
    setopenD(false);
    setMobileOpen(!mobileOpen);
  };
  const [openD, setopenD] = React.useState(true);
  const handleClickD = () => {
    setopenD(!openD);
  };
  let navigate = useNavigate();
  const logOut = useCallback(() => {
    localStorage.removeItem("user");
    localStorage.clear();

    dispatch(logout());
    setAnchorEl(null);
    setTimeout(function() {
      localStorage.clear();
      localStorage.removeItem("user");
      navigate("/login");
    }, 1000);
  }, [dispatch]);

  const logOutNew = () => {
    setTimeout(function() {
      localStorage.clear();
      localStorage.removeItem("user");
      navigate("/login");
    }, 1000);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (namestr) => {
    let name = "";
    let parseJson = {};
    if (Object.keys(user).length != 0) {
      if (user.hasOwnProperty("first_name")) {
        name = user.first_name + " " + user.last_name;
      } else {
        parseJson = JSON.parse(JSON.stringify(loginUser));
        name = parseJson.first_name + " " + parseJson.last_name;
      }
    } else if (Object.keys(loginUser).length != 0) {
      parseJson = JSON.parse(JSON.stringify(loginUser));
      name = parseJson.first_name + " " + parseJson.last_name;
    } else {
      return "";
    }
    return {
      // sx: {
      //   bgcolor: "red",
      // },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" style={{ color: "blue" }} sx={{ my: 2 }}>
        {t("menu_top.application_header_name")}
      </Typography>
      <Divider />
      {isLoggedIn ? (
        <Button>
          {user != null ? (
            <>
              <Avatar {...stringAvatar(user)} />
            </>
          ) : (
            <></>
          )}
        </Button>
      ) : (
        ""
      )}

      <Divider />
      {/* <List>
        {navItemsObject.map((item) => (
          <Link
            key={item.name}
            style={{ textDecoration: "none" }}
            to={item.path}
          >
            <ListItem key={item.name} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}

        <Link key="11" style={{ textDecoration: "none" }} to="/">
          <ListItem key="11" disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </Link>
      </List> */}

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Nested List Items
        //   </ListSubheader>
        // }
      >
        {localStorage.getItem("lang") === "en" ||
        localStorage.getItem("lang") == null ? (
          <>
            {/* <Button id="lnkHome" sx={{ color: "#fff" }}>
              Change to {currentLanguageText}
            </Button>{" "} */}
            <Link key="100" style={{ textDecoration: "none" }} to="/">
              <ListItemButton>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText
                  onClick={(event) => {
                    i18n.changeLanguage("hi");
                    //Now set the current language in local storage
                    localStorage.setItem("lang", "hi");
                  }}
                  primary="मराठी भाषा"
                />
              </ListItemButton>
            </Link>
          </>
        ) : (
          <>
            {/* <Button
              id="lnkHome"
              sx={{ color: "#fff" }}
              
            >
              Change to English
            </Button> */}

            <Link key="101" style={{ textDecoration: "none" }} to="/">
              <ListItemButton>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText
                  onClick={(event) => {
                    i18n.changeLanguage("en");

                    //Now set the current language in local storage
                    localStorage.setItem("lang", "en");
                  }}
                  primary="Change to English"
                />
              </ListItemButton>
            </Link>
          </>
        )}

        {/* <Link key="1" style={{ textDecoration: "none" }} to="/">
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.home")}
            />
          </ListItemButton>
        </Link> */}

        {(isLoggedIn && loginUser && loginUser.roles == "super_admin") ||
        loginUser.roles == "admin" ? (
          <>
            {" "}
            <Link key="2" style={{ textDecoration: "none" }} to="/admin">
              <ListItemButton>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText onClick={handleDrawerToggle} primary="Admin" />
              </ListItemButton>
            </Link>
          </>
        ) : (
          ""
        )}

        {/* <Link key="jobs" style={{ textDecoration: "none" }} to="/job-updates">
          <ListItemButton>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText onClick={handleDrawerToggle} primary="Jobs" />
          </ListItemButton>
        </Link> */}

        {/* <Link key="72" style={{ textDecoration: "none" }} to="/business">
          <ListItemButton>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.business")}
            />
          </ListItemButton>
        </Link> */}

        {/* <Link key="15" style={{ textDecoration: "none" }} to="/gallery">
          <ListItemButton>
            <ListItemIcon>
              <PhotoLibraryOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.gellery")}
            />
          </ListItemButton>
        </Link> */}
        {isLoggedIn ? (
          <>
            {/* <Link key="20" style={{ textDecoration: "none" }} to="/dashboard">
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  onClick={handleDrawerToggle}
                  primary={t("menu_top.dashboard")}
                />
              </ListItemButton>
            </Link> */}
          </>
        ) : (
          ""
        )}

        {/* <Link key="4" style={{ textDecoration: "none" }} to="/committee">
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.committee_member")}
            />
          </ListItemButton>
        </Link> */}

        {isLoggedIn ? (
          ""
        ) : (
          <Link key="5" style={{ textDecoration: "none" }} to="/matrimony">
            <ListItemButton>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText
                onClick={handleDrawerToggle}
                primary={t("menu_top.vadhu_var")}
              />
            </ListItemButton>
          </Link>
        )}

        {isLoggedIn ? (
          <>
            <ListItemButton onClick={handleClickD}>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu_top.vadhu_var")} />
              {openD ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openD} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link
                  key="7"
                  style={{ textDecoration: "none" }}
                  to="/matrimony"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <ViewStreamOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      onClick={handleDrawerToggle}
                      primary={t("menu_top.vadhu_var_list")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  key="8"
                  style={{ textDecoration: "none" }}
                  to="/my-registration"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <HowToRegIcon />
                    </ListItemIcon>
                    <ListItemText
                      onClick={handleDrawerToggle}
                      primary={t("menu_top.my_registration")}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  key="899"
                  style={{ textDecoration: "none" }}
                  to="/registration"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <PersonAddAltOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      onClick={handleDrawerToggle}
                      primary={t("menu_top.vadhu_var_registration")}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </>
        ) : (
          ""
        )}

        <Link key="88" style={{ textDecoration: "none" }} to="/registration">
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.vadhu_var_registration")}
            />
          </ListItemButton>
        </Link>

        <Link key="19" style={{ textDecoration: "none" }} to="/contact">
          <ListItemButton>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText
              onClick={handleDrawerToggle}
              primary={t("menu_top.conatct")}
            />
          </ListItemButton>
        </Link>

        {isLoggedIn ? (
          ""
        ) : (
          <Link key="10" style={{ textDecoration: "none" }} to="/login">
            <ListItemButton>
              <ListItemIcon>
                <LoginOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                onClick={handleDrawerToggle}
                primary={t("menu_top.login")}
              />
            </ListItemButton>
          </Link>
        )}

        {isLoggedIn ? (
          <Link
            key="5"
            onClick={logOut}
            style={{ textDecoration: "none" }}
            to="/logout"
          >
            <ListItemButton>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                onClick={handleDrawerToggle}
                primary={t("menu_top.logout")}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {/* <ListItemButton>
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText onClick={handleDrawerToggle} primary="Drafts" />
        </ListItemButton> */}
        {/* 
        <ListItemButton onClick={handleClickD}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
          {openD ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openD} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText onClick={handleDrawerToggle} primary="Starred" />
            </ListItemButton>
          </List>
        </Collapse> */}
      </List>
    </Box>
  );

  const [currentLanguageText, setCurrentLanguageText] = React.useState("मराठी");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorElMatrimony, setAnchorElMatrimony] = React.useState(null);

  const open = Boolean(anchorEl);

  const openMatrimony = Boolean(anchorElMatrimony);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickMatrimony = (event) => {
    if (isLoggedIn) {
      setAnchorElMatrimony(event.currentTarget);
    } else {
      return navigate("/login");
    }
  };
  const handleClose = () => {
    setAnchorElMatrimony(null);
    setAnchorEl(null);
  };

  const navigateToMatrimony = () => {
    setAnchorElMatrimony(null);
    if (isLoggedIn) {
      return navigate("/matrimony");
    } else {
      return navigate("/login");
    }
  };
  const navigateToMatrimonyRegistration = () => {
    setAnchorElMatrimony(null);
    navigate(`/registration`, {
      state: {
        fromPage: "myRegistration",
        data: "",
        //parentRoute: window.location.pathname,
      },
    });

    // if (isLoggedIn) {

    // } else {
    //   return navigate("/login");
    // }
  };

  const navigateListRegistration = () => {
    setAnchorElMatrimony(null);
    if (isLoggedIn) {
      return navigate("/my-registration");

      // if (user["roles"] == "member") {
      //   return navigate("/dashboard");
      // } else {

      // }
    } else {
      return navigate("/login");
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="subtitle1"
            size="small"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            {t("menu_top.application_header_name")}
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {localStorage.getItem("lang") === "en" ||
            localStorage.getItem("lang") == null ? (
              <>
                <Button
                  size="small"
                  id="lnkHome"
                  sx={{ color: "#fff" }}
                  onClick={(event) => {
                    //object.member();  onClick={changeLang("en")}
                    i18n.changeLanguage("hi");

                    //Now set the current language in local storage
                    localStorage.setItem("lang", "hi");
                  }}
                >
                  मराठी भाषा
                </Button>{" "}
              </>
            ) : (
              <>
                <Button
                  size="small"
                  id="lnkHome"
                  sx={{ color: "#fff" }}
                  onClick={(event) => {
                    //object.member();  onClick={changeLang("en")}
                    i18n.changeLanguage("en");

                    //Now set the current language in local storage
                    localStorage.setItem("lang", "en");
                  }}
                >
                  Change to English
                </Button>
              </>
            )}

            {/* <Button
              size="small"
              id="lnkHome"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/home");
              }}
            >
              {t("menu_top.home")}
            </Button> */}

            {(isLoggedIn && loginUser && loginUser.roles == "super_admin") ||
            loginUser.roles == "admin" ? (
              <>
                <Button
                  size="small"
                  id="lnkAbout"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    return navigate("/Admin");
                  }}
                >
                  Admin
                </Button>
              </>
            ) : (
              <> </>
            )}

            {/* <Button
              size="small"
              id="lnkBusiness"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/business");
              }}
            >
              {t("menu_top.business")}
            </Button> */}

            {/* <Button
              id="lnkGallary"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/gallery");
              }}
            >
              {t("menu_top.gellery")}
            </Button> */}
            {isLoggedIn
              ? // <Button
                //   size="small"
                //   id="lnkDashboard"
                //   sx={{ color: "#fff" }}
                //   onClick={() => {
                //     return navigate("/dashboard");
                //   }}
                // >
                //   {t("menu_top.dashboard")}
                // </Button>
                ""
              : ""}
            {/* <Button
              size="small"
              id="lnkCommittee"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/committee");
              }}
            >
              {t("menu_top.committee_member")}
            </Button> */}

            <Button
              size="small"
              sx={{ color: "#fff" }}
              id="basic-button-1"
              aria-controls={openMatrimony ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMatrimony ? "true" : undefined}
              onClick={handleClickMatrimony}
            >
              {t("menu_top.vadhu_var")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElMatrimony}
              open={openMatrimony}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button-1",
              }}
            >
              <MenuItem onClick={navigateToMatrimony}>
                {" "}
                {t("menu_top.vadhu_var_list")}
              </MenuItem>
              <MenuItem onClick={navigateListRegistration}>
                {t("menu_top.my_registration")}
              </MenuItem>
              <MenuItem onClick={navigateToMatrimonyRegistration}>
                {t("menu_top.vadhu_var_registration")}
              </MenuItem>
            </Menu>

            <Button
              size="small"
              id="lnkreg"
              sx={{ color: "#fff" }}
              onClick={navigateToMatrimonyRegistration}
            >
              {t("menu_top.vadhu_var_registration")}
            </Button>

            {/* <Button
              size="small"
              id="lnkJobUpdates"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/job-updates");
              }}
            >
              Jobs
            </Button> */}

            <Button
              size="small"
              id="lnkGallary"
              sx={{ color: "#fff" }}
              onClick={() => {
                return navigate("/contact");
              }}
            >
              {t("menu_top.conatct")}
            </Button>

            {isLoggedIn == false ? (
              <>
                {" "}
                <Button
                  size="small"
                  id="lnkGallary"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    return navigate("/login");
                  }}
                >
                  {t("menu_top.login")}
                </Button>
              </>
            ) : (
              <> </>
            )}

            {isLoggedIn ? (
              <Button
                size="small"
                sx={{ color: "#fff" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {/* <Avatar
                  alt=""
                  src="https://stock.adobe.com/hu/search/images?k=default+profile+picture?auto=compress&cs=tinysrgb&dpr=2&w=500"
                /> */}
                {/* <Avatar alt="" sx={{ m: 1, bgcolor: "secondary.main" }}>
                  {user && user.last_name}
                </Avatar> */}

                {1 == 1 ? (
                  <>
                    <Avatar {...stringAvatar(user)} />
                  </>
                ) : (
                  <></>
                )}
              </Button>
            ) : (
              ""
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>

              <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={logOut}> {t("menu_top.logout")}</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Grid>
          <Grid item sm={2} xs={2}>
            <Leftbar />
          </Grid>
          <Grid item sm={7} xs={10}>
            <Feed />
          </Grid>
          <Grid item sm={3} className={classes.right}>
            <Rightbar />
          </Grid>
        </Grid>
       
      </Box> */}
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
