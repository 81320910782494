import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import Textfield from "../FormsUI/Textfield";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { default as StyledButton } from "../FormsUI/Button";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveContactUs } from "../../actions/auth";
import Divider from "@mui/material/Divider";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));
const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const formikRef = useRef();
  const navigate = useNavigate();
  const [configsData, setConfigsData] = useState({});
  useEffect(() => {
    fetch(`data/configs.json`)
      .then((res) => res.json())
      .then((data) => {
        setConfigsData({ ...data });
      })
      .catch((err) => {});
  }, []);

  const INITIAL_FORM_STATE = {
    fname: "",
    lname: "",
    mobile_or_email: "",
    subject: "",
    description: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    fname: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),
    lname: Yup.string()
      .max(30)
      .required(t("matrimony_registration_page.lbl_required_error")),
    mobile_or_email: Yup.string()
      .max(25)
      .required(t("matrimony_registration_page.lbl_required_error")),
    subject: Yup.string()
      .max(200)
      .required(t("matrimony_registration_page.lbl_required_error")),
    description: Yup.string()
      .max(250)
      .required(t("matrimony_registration_page.lbl_required_error")),
  });

  const handlerSubmit = (formValue) => {
    dispatch(saveContactUs(formValue, t)).then((res) => {
      formikRef.current.setFieldValue("fname", "", false);
      formikRef.current.setFieldValue("lname", "", false);
      formikRef.current.setFieldValue("mobile_or_email", "", false);
      formikRef.current.setFieldValue("subject", "", false);
      formikRef.current.setFieldValue("description", "", false);
    });
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left"></Box>
        </Grid>
        <Grid item lg={8} xs={8} md={8}>
          <Box display="flex" justifyContent="center">
            <Button>{t("contact_page.lbl_page_title")}</Button>
          </Box>
        </Grid>
        <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="right"></Box>
        </Grid>
      </Grid>
      <div className={classes.marginTopGrid}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} md={12}>
            <Grid item lg={12} xs={12} md={12}>
              {/* <Typography variant="h6" align="center">
              Address :
            </Typography> */}
              {/* <Typography
                variant="subtitle1"
                gutterBottom
                align="center"
              ></Typography> */}
              {/* <Typography variant="h6" align="center">
                Contact
              </Typography> */}
              {/* <Typography variant="subtitle1" gutterBottom align="center">
                {t("contact_page.lbl_trust_name")}
              </Typography>
              <Typography variant="subtitle1" gutterBottom align="center">
                Aishwaryam Hamara society A08, GAT No 94, Dehu - Alandi Rd, near
                SNBP International School, Jadhav Wadi, Chikhali,
                Pimpri-Chinchwad, Maharashtra 411062
              </Typography> */}

              {configsData ? (
                <>
                  <Typography variant="subtitle1" gutterBottom align="center">
                    {t("contact_page.lbl_email")} : {configsData.email}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom align="center">
                    {t("contact_page.lbl_mobile")} {configsData.contcat}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom align="center">
                    <Button
                      startIcon={<WhatsAppIcon fontSize="small" />}
                      color="primary"
                      size="small"
                      onClick={() => {
                        window.open(
                          configsData.customer_care_whatapp_link,
                          "_blank"
                        );
                      }}
                    >
                      Chat on whatapp
                    </Button>
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item lg={12} xs={12} md={12}>
              <Divider variant="middle" />
              <div className={classes.marginTopGrid}>
                {configsData ? (
                  <>
                    <Typography variant="subtitle1" gutterBottom align="center">
                      {t("contact_page.lbl_information")} {configsData.contcat}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12} md={12}>
            <Formik
              innerRef={formikRef}
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                handlerSubmit(values);
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12} md={12}>
                    <Textfield
                      name="fname"
                      label={t("contact_page.lbl_fname")}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} md={12}>
                    <Textfield
                      name="lname"
                      label={t("contact_page.lbl_lname")}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} md={12}>
                    <Textfield
                      name="mobile_or_email"
                      label={t("contact_page.lbl_contact")}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} md={12}>
                    <Textfield
                      name="subject"
                      label={t("contact_page.lbl_subject")}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} md={12}>
                    <Textfield
                      name="description"
                      label={t("contact_page.lbl_description")}
                      multiline={true}
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.item}>
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: 20 }}
                      >
                        {t("common_lbl.btn_submit")}
                      </StyledButton>
                    </div>
                  </Grid>
                  <Grid item lg={12} xs={12} md={12}></Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Contact;
