import businessService from "../services/business-services";
import { showSuccessSnackbar } from "./snackbarActions";

export const getMasterCategories = (requestData) => (dispatch) => {
  //   dispatch({
  //     type: COMMITTEE_MEMBER_LIST_REQUEST,
  //   });
  return businessService.getMasterCategories(requestData).then(
    (response) => {
      //   dispatch({
      //     type: COMMITTEE_MEMBER_LIST_SUCCESS,
      //     payload: { commiteeMembers: response.data.data },
      //   });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      //   dispatch({
      //     type: COMMITTEE_MEMBER_LIST_FAIL,
      //   });
      return Promise.reject(error);
    }
  );
};

export const registration = (requestData, t) => (dispatch) => {
  //   dispatch({
  //     type: "VADHUVAR_REGISTRATION_SUBMIT_REQUEST",
  //   });
  return businessService.businessRegistration(requestData).then(
    (response) => {
      //   dispatch({
      //     type: "VADHUVAR_REGISTRATION_SUBMIT_SUCCESS",
      //   });
      let msg = response.data.message.toString();
      dispatch(
        showSuccessSnackbar({
          hideDuration: 10000,
          status: response.data.status,
          message: msg,
        })
      );
      dispatch({
        type: "BUSINESS_LIST_CLEAR",
      });
      //   dispatch({
      //     type: VADHUVAR_LIST_APPROVAL_REFRESH,
      //   });
      return Promise.resolve(response.data.data);
    },
    (error) => {
      //   dispatch({
      //     type: "VADHUVAR_REGISTRATION_SUBMIT_FAILED",
      //   });
      return Promise.reject(error.response.data);
    }
  );
};

export const getApprovedBusiness = (requestData) => (dispatch) => {
  dispatch({
    type: "BUSINESS_LIST_REQUEST",
  });
  return businessService.getBusinessList(requestData).then(
    (response) => {
      dispatch({
        type: "BUSINESS_LIST_SUCCESS",
        payload: { businessList: response.data.data },
      });
      return Promise.resolve(response.data.data);
    },
    (errorResp) => {
      dispatch({
        type: "BUSINESS_LIST_FAILED",
      });
      return Promise.reject(errorResp);
    }
  );
};

export const businessAction = (requestData, t) => (dispatch) => {
  return businessService.businessAction(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      // dispatch({
      //   type: VADHUVAR_LIST_REFRESH,
      // });
      // dispatch({
      //   type: VADHUVAR_LIST_APPROVAL_REFRESH,
      // });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const businessListClear = (requestData) => (dispatch) => {
  dispatch({
    type: "BUSINESS_LIST_CLEAR",
  });
};
export const updateBannerImage = (requestData, t) => (dispatch) => {
  return businessService.updateBannerImage(requestData).then(
    (response) => {
      dispatch(
        showSuccessSnackbar({
          status: response.data.status,
          message: response.data.message,
        })
      );
      dispatch({
        type: "BUSINESS_LIST_CLEAR",
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getBusinessDetailsById = (requestData, t) => (dispatch) => {
  return businessService.getBusinessDetailsById(requestData).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
