import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Box,
  Button,
  CardActions,
  Avatar,
} from "@material-ui/core";
import { Link, Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";

import VadhuVarRegistration from "../vadhuVar/VadhuVarRegistration";
import Leftbar from "../menu/Leftbar";
import Main from "../PublicNoAuth/Main";
import SwipeableTextMobileStepper from "../../components/commonComponent/SwipeableTextMobileStepper";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  avatarImage: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  var userObject = {};
  const getLoginUserDetails = localStorage.getItem("user");
  if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
    userObject = JSON.parse(getLoginUserDetails);
  }
  const [user, setUser] = useState(userObject);
  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/home");
    }
  });
  const serviceMenu = [
    {
      id: "01",
      lable: "Vadhu Var",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbtFzYOUjBdRpw77oOHTcwf3dZJ8O5H06lMw&usqp=CAU",
      navigationPath: "/matrimony",
    },
    {
      id: "02",
      lable: "Jobs",
      imagePath:
        "https://www.seekpng.com/png/detail/212-2129332_job-opening-it-support-officer-job.png",
      //"https://freepngimg.com/thumb/jobs/9-2-jobs-png-thumb.png",
      navigationPath: "/job-updates",
    },
    {
      id: "03",
      lable: "Business",
      imagePath:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqjCpAvZ2x7FH_ilHR_nFWc3ty2ssiazsedA&usqp=CAU",
      navigationPath: "/business",
    },
  ];

  return (
    <>
      {/* <nav>
        <Link to="/dashboard/a">My Orders</Link>
        <Link to="/dashboard/users">My Details</Link>
        <Link to="/settings">Settings</Link>
      </nav> */}

      {/* <Grid container>
        <Grid item sm={2} xs={2}>
          <Leftbar />
        </Grid>
        <Grid item sm={10} xs={10}>
          <Outlet />
          <Routes>
            <Route path="" element={<Main />} />
            <Route path="a" element={<Main />} />
            <Route path="users" element={<VadhuVarRegistration />} />
          </Routes>
        </Grid>
      </Grid> */}

      {/* <Grid item sm={2} xs={2}>
        <Leftbar />
      </Grid>
      <Grid item sm={8} xs={8}>
        <Outlet />
        <Routes>
          <Route path="" element={<Main />} />
          <Route path="a" element={<Main />} />
          <Route path="users" element={<UserList />} />
        </Routes>
      </Grid> */}

      <Grid lg={12}>
        <Typography variant="h6" style={{ color: "blue" }} align="center">
          <Button size="large" color="primary">
            {t("welcomet_to_home")}
          </Button>
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          {/* <SRLWrapper options={options}> */}
          <SwipeableTextMobileStepper></SwipeableTextMobileStepper>
          {/* </SRLWrapper> */}
        </Grid>

        <Grid item lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Button color="primary" size="medium">
              {t("menu_top.ourServices")}
            </Button>
          </Box>
        </Grid>

        {/* <Grid item lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Button
              color="primary"
              size="small"
              onClick={() => {
                return navigate("/matrimony");
              }}
            >
              {t("menu_top.vadhu_var")}
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                return navigate("/job-updates");
              }}
            >
              Jobs
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                return navigate("/business");
              }}
            >
              {t("menu_top.business")}
            </Button>

            <Button
           
              color="primary"
              size="small"
              onClick={() => {
                return navigate("/committee");
              }}
            >
              {t("menu_top.committee_member")}
            </Button>
          </Box>
        </Grid> */}

        <Grid lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1" gutterBottom>
              Welcome to suryavanshi bari portal,one of the best platform for
              our community where we have provided some services
            </Typography>
          </Box>
        </Grid>
        <Grid lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Grid container spacing={2}>
              {serviceMenu.map((item) => (
                <Grid key={item.id} item lg={4} xs={12} md={12}>
                  {/* <Paper className={classes.paper} elevation={2}> */}
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                  >
                    <Button
                      onClick={() => {
                        return navigate(item.navigationPath);
                      }}
                    >
                      <Avatar
                        alt="Test "
                        className={classes.avatarImage}
                        src={item.imagePath}
                      />
                    </Button>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          return navigate(item.navigationPath);
                        }}
                      >
                        {item.lable}
                      </Button>
                    </CardActions>
                  </Grid>
                  {/* </Paper> */}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Button color="primary" size="medium"></Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
