import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Paper, Avatar } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "../card.css";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import { getListForApproval } from "../../actions/vadhuVar-action";

//import configData from "../../config/configData.json";
import { Alert, AlertTitle } from "@mui/material";
import * as appConstant from "../../helpers/appConstant";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Marquee from "react-fast-marquee";
const IMAGE_API_URL =
  process.env.REACT_APP_ENV == "DEV"
    ? process.env.REACT_APP_DEV_IMAGE_SERVER_URL
    : process.env.REACT_APP_PROD_IMAGE_SERVER_URL;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  avatarImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
  },
  marginTopGrid: {
    marginTop: "10px",
  },
}));

const VadhuVarList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { vadhuVar, loading, loaded } = useSelector(
    (state) => state.vadhuVarApprovalReducer
  );

  var userObject = {};
  const getLoginUserDetails = localStorage.getItem("user");
  if (getLoginUserDetails != null || getLoginUserDetails != undefined) {
    userObject = JSON.parse(getLoginUserDetails);
  }
  const [user, setUser] = useState(userObject);
  const default_profile_images = IMAGE_API_URL + "thumbnail/get-vadhu-var";

  const columns = [
    {
      field: "associate_name",
      headerName: "Associate Name",
      width: 155,
      editable: true,
    },
    { field: "region", headerName: "Region", width: 155, editable: true },
    {
      field: "full_name",
      headerName: "Vadhu var name",
      width: 250,
      editable: true,
    },
    {
      field: "registration_number",
      headerName: "Reg. no",
      width: 100,
      editable: true,
    },
    {
      field: "created_on",
      headerName: "Created on",
      width: 175,
      editable: true,
    },
    {
      field: "login_full_name",
      headerName: "Created by",
      width: 175,
      editable: true,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 120,
      renderCell: (cellValues) => {
        let paymentStatus = cellValues.row.payment_status;
        return (
          <>
            <Button
              variant="text"
              size="small"
              color={paymentStatus == "Done" ? "primary" : "error"}
              onClick={(event) => {}}
            >
              {paymentStatus}
            </Button>
          </>
        );
      },
    },
    // {
    //   field: "Status",
    //   width: 120,
    //   renderCell: (cellValues) => {
    //     let rowStatus = cellValues.row.registration_status;
    //     return (
    //       <Button
    //         variant="text"
    //         size="small"
    //         color={rowStatus == "Pending" ? "primary" : "secondary"}
    //         onClick={(event) => {}}
    //       >
    //         {rowStatus}
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "Active",
      width: 120,
      renderCell: (cellValues) => {
        let rowStatus = cellValues.row.row_status;
        return (
          <Button
            variant="text"
            size="small"
            color={rowStatus == 1 ? "primary" : "secondary"}
            onClick={(event) => {}}
          >
            {rowStatus == 1 ? "Active" : "Deactive"}
          </Button>
        );
      },
    },
    {
      headerName: "Connect",
      width: 150,
      renderCell: (cellValues) => {
        let mobile = "+91" + cellValues.row.login_mobile;
        return (
          <>
            <Button
              startIcon={<WhatsAppIcon fontSize="small" />}
              color="primary"
              size="small"
              onClick={() => {
                window.open("https://wa.me/" + mobile, "_blank");
              }}
            >
              {cellValues.row.login_mobile}
            </Button>
          </>
        );
      },
    },
    {
      field: "View",
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => {
                handleView(cellValues);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [profileNeedToApprove, setProfileNeedToApprove] = useState(0);

  useEffect(() => {
    if (isLoggedIn == false) {
      return navigate("/login");
    }

    if (loaded == false) {
      let data = {};
      dispatch(getListForApproval(data)).then((data) => {
        if (Object.keys(data).length === 0) {
        } else {
          setTimeout(function() {
            let updatedItem = data.filter((element) => {
              return user && user.roles == "member"
                ? element
                : element.registration_status === "Pending";
            });
            setItems(updatedItem);
            manageCount(data);
          }, 1000);
        }
      });
    } else {
      if (Object.keys(vadhuVar).length === 0) {
      } else {
        let updatedItem = vadhuVar.filter((element) => {
          return user && user.roles == "member"
            ? element
            : element.registration_status === "Pending";
        });
        setItems(updatedItem);
        manageCount(vadhuVar);
      }
    }
  }, [refreshKey]);

  const handleView = (selectedRow) => {
    navigate(`/details`, {
      state: { data: selectedRow.row, parentRoute: window.location.pathname },
    });
  };

  const manageCount = (inputList) => {
    let approvedItem = inputList.filter((element) => {
      return element.registration_status === "Approved";
    });
    setApproved(approvedItem.length);
    let pendingItem = inputList.filter((element) => {
      return element.registration_status === "Pending";
    });
    setPending(pendingItem.length);

    let rejectedItem = inputList.filter((element) => {
      return element.registration_status === "Rejected";
    });
    setRejected(rejectedItem.length);

    let profileNeedToApproveItem = inputList.filter((element) => {
      return parseInt(element.is_profile_img_newly_uploded) === 1;
    });
    setProfileNeedToApprove(profileNeedToApproveItem.length);
  };

  const handleTabPending = (e) => {
    if (Object.keys(vadhuVar).length != 0) {
      let updatedItem = vadhuVar.filter((element) => {
        return element.registration_status === "Pending";
      });
      setItems(updatedItem);
    }
  };
  const handleTabApproved = (e) => {
    if (Object.keys(vadhuVar).length != 0) {
      let updatedItem = vadhuVar.filter((element) => {
        return element.registration_status === "Approved";
      });
      setApproved(updatedItem.length);
      setItems(updatedItem);
    }
  };
  const handleTabRejected = (e) => {
    if (Object.keys(vadhuVar).length != 0) {
      let updatedItem = vadhuVar.filter((element) => {
        return element.registration_status === "Rejected";
      });
      setItems(updatedItem);
    }
  };
  const handleTabVerifyPhoto = () => {
    if (Object.keys(vadhuVar).length != 0) {
      let updatedItem = vadhuVar.filter((element) => {
        return (
          parseInt(element.is_profile_img_newly_uploded) === 1 &&
          element.registration_status === "Approved"
        );
      });
      setItems(updatedItem);
    }
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          background: "#cfd8dc",
        }}
      >
        {/* <Grid item lg={2} xs={2} md={2}>
          <Box display="flex" justifyContent="left">
            
          </Box>
        </Grid> */}

        <Grid item lg={6} xs={6} md={6}>
          <Box display="flex" justifyContent="left">
            <Button> {t("menu_top.my_registration")}</Button>
          </Box>
        </Grid>
        <Grid item lg={6} xs={6} md={6}>
          <Box display="flex" justifyContent="right">
            {/* <Button>Right</Button> */}
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                navigate(`/registration`, {
                  state: {
                    fromPage: "myRegistration",
                    data: "",
                    parentRoute: window.location.pathname,
                  },
                });
              }}
            >
              {t("matrimony_registration_page.title")}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.marginTopGrid}>
        <Grid container spacing={2}>
          {user && user.roles == "member" ? (
            <>
              <Grid item lg={12} xs={12} md={12}>
                {items.length == 0 ? (
                  <>
                    <Alert variant="outlined" severity="warning">
                      {t("matrimony_page.lbl_not_done_registration")}
                    </Alert>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              {items &&
                items.map((item) => (
                  <Grid key={item.id} item lg={3} xs={12} md={12}>
                    <Paper className={classes.paper} elevation={2}>
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          alt={item.first_name}
                          className={classes.avatarImage}
                          src={
                            default_profile_images + item.appended_image_path
                          }
                        />
                        <CardContent>
                          <Typography
                            align="center"
                            gutterBottom
                            variant="headline"
                            component="h4"
                          >
                            {item.first_name} {item.middle_name}{" "}
                            {item.last_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            align="center"
                            color="text.secondary"
                          >
                            {t("matrimony_page.lbl_registration_number")} :{" "}
                            {item.registration_number} <br />
                            {t("matrimony_page.lbl_education")} :{" "}
                            {item.education} <br />
                            {t("matrimony_page.lbl_region")} : {item.region}{" "}
                            <br />
                            {t(
                              "matrimony_details_page.lbl_profile_status"
                            )} : {item.registration_status}{" "}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            id={item.id}
                            onClick={(event) => {
                              let selectedData = vadhuVar.filter(
                                (e) => e.id == event.currentTarget.id
                              );
                              return navigate(`/details`, {
                                state: {
                                  data: selectedData[0],
                                  parentRoute: window.location.pathname,
                                },
                              });
                            }}
                          >
                            Details
                          </Button>
                        </CardActions>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={"Pending (" + pending + ")"}
                  onClick={handleTabPending}
                  {...a11yProps(0)}
                />
                <Tab
                  label={"Approved (" + approved + ")"}
                  onClick={handleTabApproved}
                  {...a11yProps(1)}
                />
                <Tab
                  label={"Rejected (" + rejected + ")"}
                  onClick={handleTabRejected}
                  {...a11yProps(2)}
                />
                <Tab
                  label={"Verify Profile photo (" + profileNeedToApprove + ")"}
                  onClick={handleTabVerifyPhoto}
                  {...a11yProps(3)}
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div style={{ height: 450, width: "100%" }}>
                  <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </TabPanel>
            </Box>
          )}
        </Grid>
      </div>
    </>
  );
};

export default VadhuVarList;
