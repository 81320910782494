import axiosInstance from "../middleware/axiosConfig";

const createUpdateJobProfile = (requestData) => {
  return axiosInstance.post("job/create-job-profile", requestData);
};

const getJobProfileList = (requestData) => {
  return axiosInstance.post("job/get-job-profile", requestData);
};

const createUpdateJobPost = (requestData) => {
  return axiosInstance.post("job/job-post", requestData);
};
const getPostedJobList = (requestData) => {
  return axiosInstance.post("job/get-posted-job", requestData);
};

const postedJobActions = (requestData) => {
  return axiosInstance.post("job/posted-job-actions", requestData);
};

const getPostedJobDetailsById = (requestData) => {
  return axiosInstance.post("job/get-posted-job-details-by-id", requestData);
};

const getMyPostedJob = (requestData) => {
  return axiosInstance.post("job/get-my-posted-job", requestData);
};

export default {
  createUpdateJobProfile,
  getJobProfileList,
  createUpdateJobPost,
  getPostedJobList,
  postedJobActions,
  getPostedJobDetailsById,
  getMyPostedJob,
};
